import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import "./App.scss";
import { store } from "./store";

import Routing from "./Routing";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "next-themes";
import { Toaster } from "sonner";
import { SidebarProvider } from "./components/ui/sidebar";

export default function App() {
  return (
    <ThemeProvider attribute="class" defaultTheme="dark">
      {/* @ts-ignore */}
      <CookiesProvider>
        {/* @ts-ignore */}
        <Provider store={store}>
          <Router>
            <SidebarProvider>
              <Toaster />
              <div className="w-full">
                <Routing />
              </div>
            </SidebarProvider>
          </Router>
        </Provider>
      </CookiesProvider>
    </ThemeProvider>
  );
}
