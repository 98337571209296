import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { batch, useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  Account,
  AccountEnableResponse,
  APIError,
  Store,
  ApprovalCreateResponse,
  AccountChangePasswordResponse,
  Organisation,
  OrganisationCreateResponse,
} from "./backendTypes";

import {
  changeAccountPassword,
  changeAccountType,
  deleteAccount,
  enableAccount,
  listAccounts,
} from "./reducers/accounts";
import {
  createApproval,
  deleteApproval,
  deleteApprovalByAccountID,
  listApprovals,
} from "./reducers/approvals";
import {
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";
import { generatePassword } from "./Accounts";
import { accessScopes, csAccessScopes, findAccount } from "./lib/account-utils";
import TUtils from "./TUtils";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Loader2,
  AlertCircle,
  CircleFadingPlus,
  UserMinus,
  CirclePlus,
  UserPen,
  UserCog,
  ShieldMinus,
  ShieldPlus,
  Copy,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { AddOrganisationDialog } from "./components/AddOrganisationsDialog/AddOrganisationsDialog";
import ComboboxMultiSelect, {
  Item,
} from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { StoreApprovalDialog } from "./components/StoreApprovalDialog/StoreApprovalDialog";
import { ScopeApprovalDialog } from "./components/ScopeApprovalDialog/ScopeApprovalDialog";

// Helper functions for role and service level names
function roleToName(role: number | string | undefined) {
  if (role === 255) return "admin";
  if (role === 128) return "master";
  return "user";
}

function slToName(role: number | string | undefined) {
  if (role === 255) return "enterprise";
  if (role === 128) return "premium";
  return "normal";
}

function AccountComponent() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const deleteAccountScope = "kytron::delete_account";
  const { acid } = useParams();
  const navigate = useNavigate();
  const [loadingCsAgentUpgrade, setLoadingCsAgentUpgrade] = useState(false);

  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );

  const selectedAccount = useSelector((state: RootState) =>
    findAccount(acid, state.accounts.accounts)
  );
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>();

  const [addStoreDlgOpen, setAddStoreDlgOpen] = useState(false);
  const [addScopeDlgOpen, setAddScopeDlgOpen] = useState(false);
  const [addOrganisationDlgOpen, setAddOrganisationDlgOpen] = useState(false);
  const [changePasswordDlgOpen, setChangePasswordDlgOpen] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [deleteAccountDlgOpen, setDeleteAccountDlgOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [selectedScope, setSelectedScope] = useState<
    (typeof accessScopes)[number] | null
  >(null);

  const handleApproveScopes = async (selectedScopes: Item[]) => {
    if (!selectedAccount) {
      throw new Error("No account has been selected!");
    }

    const approvalPromises = selectedScopes.map((scope) =>
      dispatch(
        createApproval({
          acid: selectedAccount.acid,
          scope: scope.id,
          rsid: undefined,
          rstype: 0,
          session,
          backendUrl,
        })
      ).unwrap()
    );

    await Promise.all(approvalPromises);
    await dispatch(listApprovals({ session, backendUrl }));
  };

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const deleteAccountApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === deleteAccountScope),
    [account]
  );

  useEffect(() => {
    if (accounts.length === 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
  }, []);

  useEffect(() => {
    if (organisations.length === 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
  }, []);

  useEffect(() => {
    if (approvals.length === 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
  }, []);

  useEffect(() => {
    if (changePasswordDlgOpen) {
      setUserPassword(generatePassword());
    }
  }, [changePasswordDlgOpen]);

  const accountApprovalsWithoutStores = useMemo(
    () => approvals.filter((val) => val.acid === acid && val.scope !== scope),
    [approvals, acid]
  );

  const highestGrantApproval = useMemo(() => {
    const superAdmin = account?.scp?.any?.find(
      (val) => val === "kytron::grant_super_admin_access"
    );
    if (superAdmin) return superAdmin;
    const admin = account?.scp?.any?.find(
      (val) => val === "kytron::grant_admin_access"
    );
    if (admin) return admin;
    const support = account?.scp?.any?.find(
      (val) => val === "kytron::grant_site_access"
    );
    if (support) return support;
    return "";
  }, [account, acid]);

  const relevantStoreApprovals = approvals.filter(
    (x) => x.acid === selectedAccount?.acid
  );

  const relevantOrganisations = useMemo(() => {
    if (organisations && selectedAccount) {
      return (
        organisations.filter((orga) =>
          orga.accounts.find((el) => el.id === selectedAccount?.acid)
        ) ?? []
      );
    } else return [];
  }, [organisations, selectedAccount]);

  const organisationToAdd = useMemo(() => {
    const addedOrgas = relevantOrganisations.map((el) => el.id);
    return organisations.filter((el) => !addedOrgas.includes(el.id)) ?? [];
  }, [organisations, relevantOrganisations]);

  const relevantStores: Array<Store & { asis: string[] }> = [];
  const hasAllCSAgentScopes = csAccessScopes.reduce((prev, curr) => {
    if (!prev) return false;
    const hasApproval = approvals.find(
      (el) => el.acid === selectedAccount?.acid && el.scope === curr.value
    );
    return Boolean(hasApproval);
  }, true);

  stores.forEach((store) => {
    const relevantAppr = relevantStoreApprovals.filter(
      (appr) => appr.rsid === store.csid
    );
    if (!!relevantAppr.length) {
      relevantStores.push({
        ...store,
        asis: relevantAppr.map((appr) => appr.asi),
      });
    }
  });

  const handleUpdate = () => {
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
  };

  const handleEnableAccount = (checked: boolean) => {
    if (selectedAccount == undefined) {
      toast.error(
        "No account has been selected! Unable to set account status!"
      );
      return;
    }

    dispatch(
      enableAccount({
        acid: selectedAccount.acid,
        active: checked,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: AccountEnableResponse) => {
        toast.success(
          `Account '${selectedAccount.email}' has been set '${
            checked ? "active" : "inactive"
          }' (${createResponse.acid})`
        );
        dispatch(listAccounts({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to change account '${selectedAccount.email}': ${err.error}`
        );
      });
  };
  const handleApproveStoreAccess = () => {
    if (selectedStores.length === 0 || selectedAccount == undefined) {
      toast.error(
        "No account or store has been selected! Unable to approve account!"
      );
      setAddStoreDlgOpen(false);
      return;
    }

    const approvalPromises = selectedStores.map((store) =>
      dispatch(
        createApproval({
          acid: selectedAccount.acid,
          scope: "kytron::read_events",
          rsid: store.id,
          rstype: 0,
          session,
          backendUrl,
        })
      ).unwrap()
    );

    Promise.all(approvalPromises)
      .then(() => {
        setAddStoreDlgOpen(false);
        toast.success(
          `Account with email '${selectedAccount.email}' was approved for ${selectedStores.length} store(s)`
        );
        dispatch(listApprovals({ session, backendUrl }));
        setSelectedStores([]);
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to approve account with email '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const handleAddScopes = () => {
    if (!selectedScope || !selectedAccount) {
      toast.error("No scope has been selected");
      setAddStoreDlgOpen(false);
      return;
    }

    dispatch(
      createApproval({
        acid: selectedAccount.acid,
        scope: selectedScope.value,
        rsid: undefined,
        rstype: 0,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: ApprovalCreateResponse) => {
        setAddStoreDlgOpen(false);
        toast.success(
          `Scope ${selectedScope.value} has been added to account with email '${selectedAccount.email}' (${createResponse.asi})`
        );
        dispatch(listApprovals({ session, backendUrl }));
        setSelectedScope(null);
        setAddScopeDlgOpen(false);
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to approve account with email '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const handleChangePassword = () => {
    if (selectedAccount == undefined) {
      toast.error("No account has been selected! Unable to change Password!");
      setChangePasswordDlgOpen(false);
      return;
    }
    if (userPassword?.length < 8) {
      toast.error(
        "Selected Password is too short! Please type at least 8 characters!"
      );
      setChangePasswordDlgOpen(false);
      return;
    }
    dispatch(
      changeAccountPassword({
        acid: selectedAccount.acid,
        password: TUtils.hash(userPassword),
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: AccountChangePasswordResponse) => {
        setChangePasswordDlgOpen(false);
        if (createResponse.acid) {
          toast.success(
            `Password for account with email '${selectedAccount.email}' has been changed to '${userPassword}'`
          );
          navigator.clipboard.writeText(
            `Email: '${selectedAccount.email}'\nPassword: ${userPassword}`
          );
          handleUpdate();
        } else {
          toast.error(
            `Password for Account '${selectedAccount.email}' could not be changed!`
          );
        }
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to change password for Account '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const handleApproveStores = async (selectedStores: Item[]) => {
    if (!selectedAccount) {
      toast("No account has been selected!");
      return;
    }

    const approvalPromises = selectedStores.map((store) =>
      dispatch(
        createApproval({
          acid: selectedAccount.acid,
          scope: "kytron::read_events",
          rsid: store.id,
          rstype: 0,
          session,
          backendUrl,
        })
      ).unwrap()
    );

    await Promise.all(approvalPromises);
    await dispatch(listApprovals({ session, backendUrl }));
  };

  const upgradeToCSAgent = async (acid: string) => {
    if (acid === undefined) return;
    setLoadingCsAgentUpgrade(true);
    let hasError = false;

    for (const scope of csAccessScopes) {
      const currentApproval = approvals.find(
        (approval) => approval.acid === acid && approval.scope === scope.value
      );
      if (!currentApproval) {
        try {
          await dispatch(
            createApproval({
              acid: acid,
              scope: scope.value,
              rsid: undefined,
              rstype: 0,
              session,
              backendUrl,
            })
          ).unwrap();
        } catch (error: any) {
          hasError = true;
          toast.error(
            `Unable to set all customer super agent access scopes for account with id '${acid}': ${error.error}`
          );
        }
      }
    }

    try {
      await dispatch(
        changeAccountType({
          acid: acid,
          service_level: 128,
          role: 128,
          session,
          backendUrl,
        })
      ).unwrap();
    } catch (error: any) {
      hasError = true;
      toast.error(
        `Unable to upgrade account with id to a master user '${acid}': ${error.error}`
      );
    }

    if (!hasError) {
      toast.success(
        `Successfully set all customer super agent access scopes for account with id '${acid}'`
      );
    }

    setLoadingCsAgentUpgrade(false);
    handleUpdate();
  };

  const handleDeleteAccount = () => {
    setDeleteAccountDlgOpen(true);
  };

  const handleAddOrganisations = () => {
    if (selectedOrganisation && selectedAccount) {
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: selectedOrganisation?.name,
          client_number: selectedOrganisation?.client_number,
          feature_flags: selectedOrganisation?.feature_flags,
          accounts: [
            ...(selectedOrganisation?.accounts?.map((el) => ({
              id: el.id,
            })) ?? []),
            { id: selectedAccount?.acid },
          ],
          customer_sites:
            selectedOrganisation.customer_sites?.map((el) => ({ id: el.id })) ??
            [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          setAddOrganisationDlgOpen(false);
          handleUpdate();
          toast.success(
            `Account added to organisation '${selectedOrganisation?.name}'`
          );
        })
        .catch((err: APIError) => {
          toast.error(
            `Unable to add account to organisation '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const upgradeToMaster = (acid: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApprovalByAccountID({
        acid: acid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          createApproval({
            acid: acid,
            scope: "kytron::read_events",
            rsid: undefined,
            rstype: 0,
            session,
            backendUrl,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              createApproval({
                acid: acid,
                scope: manageOrganisationsApprovalScope,
                rsid: undefined,
                rstype: 0,
                session,
                backendUrl,
              })
            )
              .unwrap()
              .then(() => {
                dispatch(
                  changeAccountType({
                    acid: acid,
                    service_level: 128,
                    role: 128,
                    session,
                    backendUrl,
                  })
                )
                  .unwrap()
                  .then(() => {
                    handleUpdate();
                    toast.success(
                      `Account with id '${acid}' has been upgraded to master account.`
                    );
                  })
                  .catch(() => {
                    toast.error(`An error occured`);
                  });
              });
          });
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to upgrade account with id '${acid}': ${err.error}`
        );
      });
  };

  const removeApproval = (asis: Array<string>, scope?: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApproval({
        asis: asis,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        toast.success(
          `Account with id '${acid}' has lost approval '${scope}'.`
        );
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to remove approval for account with id '${acid}': ${err.error}`
        );
      });
  };

  const [selectedStores, setSelectedStores] = useState<Item[]>([]);

  // Convert stores to Item format for ComboboxMultiSelect
  const storeItems: Item[] = useMemo(
    () =>
      stores
        .filter(
          (store) => !relevantStores.map((s) => s.csid).includes(store.csid)
        )
        .map((store) => ({
          id: store.csid,
          name: store.name,
          tooltipContent: `${store.url} - ${store.description}`,
        })),
    [stores, relevantStores]
  );

  const handleStoreSelect = (item: Item) => {
    setSelectedStores([...selectedStores, item]);
  };

  const handleStoreRemove = (storeId: string) => {
    setSelectedStores(selectedStores.filter((store) => store.id !== storeId));
  };

  const handleClearStores = () => {
    setSelectedStores([]);
  };

  const handleAddOrganisation = async (
    orgId: string,
    autoAssignStores: boolean
  ) => {
    const organisation = organisationToAdd.find((org) => org.id === orgId);
    if (!organisation || !selectedAccount) return;

    try {
      // Add account to organization
      await dispatch(
        updateOrganisation({
          id: organisation.id,
          name: organisation.name,
          client_number: organisation.client_number,
          feature_flags: organisation.feature_flags,
          accounts: [
            ...(organisation.accounts?.map((el) => ({ id: el.id })) ?? []),
            { id: selectedAccount.acid },
          ],
          customer_sites:
            organisation.customer_sites?.map((el) => ({ id: el.id })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      ).unwrap();

      // Optionally assign store access
      if (autoAssignStores) {
        const customerSites =
          organisation.customer_sites?.map((site) => site.id) || [];
        for (const siteId of customerSites) {
          await dispatch(
            createApproval({
              acid: selectedAccount.acid,
              scope: "kytron::read_events",
              rsid: siteId,
              rstype: 0,
              session,
              backendUrl,
            })
          ).unwrap();
        }
      }

      handleUpdate();
      toast.success(`Account added to organisation '${organisation.name}'`);
    } catch (err: any) {
      toast.error(
        `Unable to add account to organisation '${organisation.name}': ${err.error}`
      );
    }
  };

  const handleRemoveOrganisation = (id: string) => {
    const relevantOrganisation = relevantOrganisations.find(
      (el) => el.id === id
    );
    if (!id || !relevantOrganisation?.id) {
      return;
    }
    if (relevantOrganisation && selectedAccount) {
      const indexInOrga = relevantOrganisation.accounts.findIndex(
        (el) => el.id === selectedAccount.acid
      );
      const removedAccounts =
        indexInOrga !== -1
          ? [
              ...relevantOrganisation.accounts.slice(0, indexInOrga),
              ...relevantOrganisation.accounts.slice(indexInOrga + 1),
            ]
          : relevantOrganisation.accounts;
      dispatch(
        updateOrganisation({
          id: relevantOrganisation.id,
          name: relevantOrganisation?.name,
          client_number: relevantOrganisation?.client_number,
          feature_flags: relevantOrganisation?.feature_flags,
          accounts: removedAccounts,
          customer_sites:
            relevantOrganisation.customer_sites?.map((el) => ({
              id: el.id,
            })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          handleUpdate();
          toast.success(`Account removed from organisation`);
        })
        .catch((err: APIError) => {
          toast.error(
            `Unable to remove account from organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleDeleteAccountAction = () => {
    if (!selectedAccount) return;

    dispatch(
      deleteAccount({
        acid: selectedAccount.acid,
        force: true,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/accounts`);
        setDeleteAccountDlgOpen(false);
        toast.success(
          `Account '${selectedAccount.email}' was successfully deleted`
        );
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to delete account '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const downgradeToUser = (acid: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApprovalByAccountID({
        acid: acid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          changeAccountType({
            acid: acid,
            service_level: 2,
            role: 2,
            session,
            backendUrl,
          })
        )
          .unwrap()
          .then(() => {
            handleUpdate();
            toast.success(
              `Account with id '${acid}' has been downgraded to user account.`
            );
          })
          .catch((err: APIError) => {
            toast.error(
              `Unable to downgrade account with id '${acid}': ${err.error}`
            );
          });
      });
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {selectedAccount?.email}
              <Badge variant={selectedAccount?.active ? "emerald" : "rose"}>
                {selectedAccount?.active ? "Active" : "Inactive"}
              </Badge>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="grid gap-4 w-full max-w-sm">
              <div className="space-y-2 items-center">
                <Label className="flex gap-2 items-center">
                  Account ID
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigator.clipboard.writeText(
                        selectedAccount?.acid || ""
                      );
                      toast("Account ID copied to clipboard");
                    }}
                    className=" p-1 hover:bg-muted rounded"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </Label>
                <Input value={selectedAccount?.acid} readOnly />
              </div>

              <div className="space-y-2">
                <Label>Role</Label>
                <Input value={roleToName(selectedAccount?.role)} readOnly />
              </div>

              <div className="space-y-2">
                <Label>Service Level</Label>
                <Input
                  value={slToName(selectedAccount?.service_level)}
                  readOnly
                />
              </div>

              <div className="flex items-center space-x-2">
                <Switch
                  checked={selectedAccount?.active}
                  onCheckedChange={handleEnableAccount}
                />
                <Label>Active</Label>
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              {Boolean(selectedAccount?.acid) && !hasAllCSAgentScopes && (
                <Button
                  variant="secondary"
                  onClick={() => upgradeToCSAgent(selectedAccount?.acid ?? "")}
                  disabled={loadingCsAgentUpgrade}
                >
                  {loadingCsAgentUpgrade ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Setting Scopes...
                    </>
                  ) : (
                    <>
                      <ShieldPlus className="mr-2 h-4 w-4" />
                      Set all customer support agent scopes
                    </>
                  )}
                </Button>
              )}

              {selectedAccount?.role === 128 && (
                <Button
                  variant="secondary"
                  onClick={() => downgradeToUser(selectedAccount?.acid)}
                >
                  <ShieldMinus className="mr-2 h-4 w-4" />
                  Downgrade to user
                </Button>
              )}

              {selectedAccount?.role && selectedAccount?.role < 128 && (
                <Button
                  variant="secondary"
                  onClick={() => upgradeToMaster(selectedAccount?.acid)}
                >
                  <UserCog className="mr-2 h-4 w-4" />
                  Upgrade to master
                </Button>
              )}

              <Button onClick={() => setChangePasswordDlgOpen(true)}>
                <UserPen className="mr-2 h-4 w-4" />
                Change Password
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Access Scopes</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Scope ID</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Scope</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {accountApprovalsWithoutStores.map((approval) => (
                  <TableRow key={approval?.asi}>
                    <TableCell className="flex gap-1 group items-center">
                      {approval?.asi}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(approval?.asi || "");
                          toast("Scope ID copied to clipboard");
                        }}
                        className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </TableCell>
                    <TableCell>
                      {accessScopes.find((el) => el.value === approval?.scope)
                        ?.label ?? approval?.scope}
                    </TableCell>
                    <TableCell className="flex group gap-1 items-center">
                      {approval?.scope}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(approval?.scope || "");
                          toast("Scope copied to clipboard");
                        }}
                        className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </TableCell>
                    <TableCell>{approval?.rstype}</TableCell>
                    <TableCell className="text-right">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              disabled={
                                !accessScopes
                                  .find((el) => el.value === approval.scope)
                                  ?.permissions?.includes(highestGrantApproval)
                              }
                              onClick={() =>
                                approval?.asi &&
                                removeApproval([approval?.asi], approval.scope)
                              }
                            >
                              <UserMinus className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Revoke access scope {approval?.scope}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button onClick={() => setAddScopeDlgOpen(true)}>
              <CirclePlus className="mr-2 h-4 w-4" />
              Add Access Scope
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Organisations</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Organisation ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {relevantOrganisations.map((org) => (
                  <TableRow
                    key={org?.id}
                    className="cursor-pointer"
                    onClick={() => navigate(`/organisations/${org.id}`)}
                  >
                    <TableCell className="group flex gap-1">
                      {org?.id}{" "}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(org?.id || "");
                          toast("Organisation ID copied to clipboard");
                        }}
                        className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </TableCell>
                    <TableCell>{org?.name}</TableCell>
                    <TableCell className="text-right">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                org?.id && handleRemoveOrganisation(org?.id);
                              }}
                            >
                              <UserMinus className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Remove account from organisation {org?.name}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {manageOrgasApproval && (
              <Button onClick={() => setAddOrganisationDlgOpen(true)}>
                <CirclePlus className="mr-2 h-4 w-4" />
                Add Organisations
              </Button>
            )}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Approved Stores</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Store ID</TableHead>
                  <TableHead>Company</TableHead>
                  <TableHead>Cluster</TableHead>
                  <TableHead>URL</TableHead>
                  <TableHead>Store</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {relevantStores.map((store) => (
                  <TableRow
                    key={store?.csid}
                    className="cursor-pointer"
                    onClick={() => navigate(`/stores/${store.csid}`)}
                  >
                    <TableCell className="flex gap-1 group items-center">
                      {store?.csid}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(store?.csid || "");
                          toast("Scope copied to clipboard");
                        }}
                        className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </TableCell>
                    <TableCell>{store?.name}</TableCell>
                    <TableCell>{store?.pg_idx}</TableCell>
                    <TableCell>{store?.url}</TableCell>
                    <TableCell>{store?.description}</TableCell>
                    <TableCell className="text-right">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                store?.asis &&
                                  removeApproval(store?.asis, scope);
                              }}
                            >
                              <UserMinus className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Revoke access to store {store?.name}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button onClick={() => setAddStoreDlgOpen(true)}>
              <CirclePlus className="mr-2 h-4 w-4" />
              Add Store
            </Button>
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={changePasswordDlgOpen}
        onOpenChange={setChangePasswordDlgOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Account Password</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="password">New Password</Label>
              <Input
                id="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setChangePasswordDlgOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleChangePassword}
              disabled={userPassword.length < 8}
            >
              Change Password
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteAccountDlgOpen}
        onOpenChange={setDeleteAccountDlgOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this account? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteAccountDlgOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteAccountAction}>
              Delete Account
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <StoreApprovalDialog
        open={addStoreDlgOpen}
        onOpenChange={setAddStoreDlgOpen}
        stores={stores}
        relevantStores={relevantStores}
        onApprove={handleApproveStores}
      />

      <ScopeApprovalDialog
        open={addScopeDlgOpen}
        onOpenChange={setAddScopeDlgOpen}
        availableScopes={accessScopes}
        highestGrantApproval={highestGrantApproval}
        existingApprovals={accountApprovalsWithoutStores}
        onApprove={handleApproveScopes}
      />

      {/* <Dialog open={addScopeDlgOpen} onOpenChange={setAddScopeDlgOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add access scope</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <Select
              value={selectedScope?.value}
              onValueChange={(value) => {
                const scope = accessScopes.find((s) => s.value === value);
                setSelectedScope(scope || null);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a scope" />
              </SelectTrigger>
              <SelectContent>
                {accessScopes
                  .filter(
                    (scope) =>
                      scope.permissions.includes(highestGrantApproval) &&
                      !accountApprovalsWithoutStores.find(
                        (approval) => approval.scope === scope.value
                      )
                  )
                  .map((scope) => (
                    <SelectItem key={scope.value} value={scope.value}>
                      {scope.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setSelectedScope(null);
                setAddScopeDlgOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleAddScopes} disabled={!selectedScope}>
              <CircleFadingPlus className="mr-2 h-4 w-4" />
              Add Scope
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog> */}

      <AddOrganisationDialog
        open={addOrganisationDlgOpen}
        onOpenChange={setAddOrganisationDlgOpen}
        organisations={organisationToAdd}
        onAdd={handleAddOrganisation}
      />

      {deleteAccountApproval && (
        <div className="space-y-4 border-t border-destructive/20 pt-6 mt-6">
          <h3 className="text-lg font-semibold text-destructive">
            Danger Zone
          </h3>
          <div className="space-y-4">
            <Button variant="destructive" onClick={handleDeleteAccount}>
              Delete Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountComponent;
