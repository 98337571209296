import { CheckCircle, Circle, Loader2, XCircle } from "lucide-react";

interface ProgressItemProps {
  name: string;
  status: "pending" | "loading" | "completed" | "error";
  error?: string;
}

const ProgressItem = ({ name, status, error }: ProgressItemProps) => {
  return (
    <div className="flex items-center space-x-2">
      {status === "loading" && (
        <div className="animate-spin h-4 w-4">
          <Loader2 className="h-4 w-4" />
        </div>
      )}
      {status === "completed" && (
        <CheckCircle className="h-4 w-4 text-green-500" />
      )}
      {status === "error" && <XCircle className="h-4 w-4 text-red-500" />}
      {status === "pending" && <Circle className="h-4 w-4 text-gray-300" />}
      <span className={status === "error" ? "text-red-500" : ""}>{name}</span>
      {error && <span className="text-sm text-red-500">({error})</span>}
    </div>
  );
};

interface ProgressTrackerProps {
  items: Array<{
    id: string;
    name: string;
    status: "pending" | "loading" | "completed" | "error";
    error?: string;
  }>;
}

const ProgressTracker = ({ items }: ProgressTrackerProps) => {
  const completedCount = items.filter(
    (item) => item.status === "completed"
  ).length;
  const totalCount = items.length;
  const progress = totalCount > 0 ? (completedCount / totalCount) * 100 : 0;

  return (
    <div className="space-y-4">
      <div className="w-full bg-secondary h-2 rounded-full overflow-hidden">
        <div
          className="bg-primary h-full transition-all duration-500 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="space-y-2">
        {items.map((item) => (
          <ProgressItem
            key={item.id}
            name={item.name}
            status={item.status}
            error={item.error}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressTracker;
