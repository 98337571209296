import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import ComboboxMultiSelect, {
  Item,
} from "../ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { CircleFadingPlus } from "lucide-react";
import ProgressTracker from "../ProgressTracker/ProgressTracker";

interface ScopeApprovalDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availableScopes: Array<{
    value: string;
    label: string;
    permissions: string[];
  }>;
  highestGrantApproval: string;
  existingApprovals: Array<{ scope: string }>;
  onApprove: (scopes: Item[]) => Promise<void>;
}

export const ScopeApprovalDialog: React.FC<ScopeApprovalDialogProps> = ({
  open,
  onOpenChange,
  availableScopes,
  highestGrantApproval,
  existingApprovals,
  onApprove,
}) => {
  const [selectedScopes, setSelectedScopes] = useState<Item[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progressItems, setProgressItems] = useState<
    Array<{
      id: string;
      name: string;
      status: "pending" | "loading" | "completed" | "error";
      error?: string;
    }>
  >([]);

  const scopeItems: Item[] = availableScopes
    .filter(
      (scope) =>
        scope.permissions.includes(highestGrantApproval) &&
        !existingApprovals.find((approval) => approval.scope === scope.value)
    )
    .map((scope) => ({
      id: scope.value,
      name: scope.label,
      tooltipContent: scope.value,
    }));

  const handleScopeSelect = (item: Item) => {
    setSelectedScopes([...selectedScopes, item]);
  };

  const handleScopeRemove = (scopeId: string) => {
    setSelectedScopes(selectedScopes.filter((scope) => scope.id !== scopeId));
  };

  const handleClearScopes = () => {
    setSelectedScopes([]);
  };

  const handleClose = () => {
    setSelectedScopes([]);
    setIsProcessing(false);
    setProgressItems([]);
    onOpenChange(false);
  };

  const handleApprove = async () => {
    if (selectedScopes.length === 0) return;

    setIsProcessing(true);
    setProgressItems(
      selectedScopes.map((scope) => ({
        id: scope.id,
        name: scope.name,
        status: "pending",
      }))
    );

    try {
      await onApprove(selectedScopes);

      // Update all items to completed
      setProgressItems((prev) =>
        prev.map((item) => ({ ...item, status: "completed" }))
      );

      // Close dialog after a brief delay to show completion state
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (error: any) {
      // Update items to error state
      setProgressItems((prev) =>
        prev.map((item) => ({
          ...item,
          status: "error",
          error: error.message,
        }))
      );
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add access scopes</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 overflow-hidden">
          {!isProcessing ? (
            <ComboboxMultiSelect
              selectedItems={selectedScopes}
              items={scopeItems}
              onItemSelect={handleScopeSelect}
              onItemRemove={handleScopeRemove}
              onClearAll={handleClearScopes}
              placeholder="Select scopes to add"
              emptyStateText="No scopes available"
              searchInputPlaceholder="Search scopes..."
              showClearAll={true}
              badgeVariant="blue"
            />
          ) : (
            <ProgressTracker items={progressItems} />
          )}
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApprove}
            disabled={!selectedScopes.length || isProcessing}
          >
            {isProcessing ? (
              <span className="flex items-center">Processing...</span>
            ) : (
              <span className="flex items-center">
                <CircleFadingPlus className="mr-2 h-4 w-4" />
                Add{" "}
                {selectedScopes.length > 0 ? `(${selectedScopes.length})` : ""}
              </span>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
