/**
 * Provides the main tracking code
 * @param {string} shopType Type of shop to create tracking code for
 * @param {boolean} enableFP Whether to enable fingerprinting or not
 * @param {string} trackingChannel Tracking channel to use.
 * @param {string} backendUrl URL for corresponding backend.
 * @param {string|undefined} csid ID of the store
 * @return {string} Tracking code for store
 */
export const getTrackingCode = (
  shopType: string,
  enableFP: boolean,
  trackingChannel: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let sv;
  let ch;
  if (trackingChannel === "production") {
    sv = enableFP ? `v2` : "";
    ch = "";
  } else {
    sv = enableFP ? `v2` : "";
    ch = "e";
  }

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://scripting.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devscripting.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/scripting";

  if (shopType === "shopify") {
    const href = `${url}/tracify${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  } else if (shopType === "woocommerce") {
    const href = `${url}/tracifyw${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  } else if (shopType === "shopware5") {
    const href = `${url}/tracifys${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  } else if (shopType === "shopware6") {
    const href = `${url}/tracifys6${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  } else if (shopType === "magento2_4") {
    const href = `${url}/tracifym2${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  } else if (shopType === "custom") {
    const href = `${url}/tracifyc${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
  <link rel="preload" as="script" href="${href}">
  <script async src="${href}"></script>`;
  }

  return "";
};

export const getCheckoutCode = (
  shopType: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://event.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devevent.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/event";

  if (shopType === "shopify")
    return `<script async src="${url}/tracify.js?eid=purchase&amp;csid=${csid}&amp;oid={{ order_number }}&amp;cm={{ customer.email | escape }}&amp;amount={{ total_price | money_without_currency }}&amp;cc={{ currency }}{% for line_item in line_items %}&amp;ITEM{{ forloop.index }}={{ line_item.sku }}&amp;AMT{{ forloop.index }}={{ line_item.line_price | money_without_currency }}&amp;QTY{{ forloop.index }}={{ line_item.quantity }}{% endfor %}"></script>`;
  else if (shopType === "woocommerce") {
    return `<!-- tracify beacon 0.0.1 | (c) tracify.ai | all rights reserved -->
  
      <?php
      add_action('woocommerce_thankyou', 'js_tracking_thank_you_page', 90, 1);
      function js_tracking_thank_you_page($order_id)
      {
        // Get the WC_Order instance Object
        $order = wc_get_order($order_id);
  
        // Output
      ?>
        <script type="text/javascript">
        function tracifyDocReady(callback) {
        // in case the document is already rendered
        if (document.readyState !== "loading") callback()
        // modern browsers
        else if (document.addEventListener)
          document.addEventListener("DOMContentLoaded", callback)
        // IE <= 8
        else
          document.attachEvent("onreadystatechange", function () {
            if (document.readyState === "complete") callback()
          })
        }
  
        function tracifyReportEvent(reportUrl, reqMethod, reqHeaders, reqBody) {
          const xmlHttp = new XMLHttpRequest()
          xmlHttp.open(reqMethod, reportUrl, true)
          for (const [key, value] of Object.entries(reqHeaders)) {
            xmlHttp.setRequestHeader(key, value)
          }
          xmlHttp.send(reqBody)
        }
  
        tracifyDocReady(() => {
          const csid = "${csid}";
  
          const order = JSON.parse('<?php echo $order; ?>');
          const params = {
          csid,
          eid: "purchase",
          oid: order.id,
          cm: order.billing.email,
          cc: order.currency,
          amount: order.total,
          };
  
          const orderedItems = []
  
          <?php
          // Loop through Order items
          foreach ($order->get_items() as $item) :
            $product = $item->get_product();
          ?>
            orderedItems.push({
            sku: '<?php echo $product->get_sku(); ?>', // product price
            amount: '<?php echo $item->get_total(); ?>',
            quantity: '<?php echo $item->get_quantity(); ?>',
            });
          <?php endforeach; // End of Loop
          ?>
  
          orderedItems.forEach((item, index) => {
          const idx = index + 1;
          params["ITEM" + idx] = item.sku;
          params["AMT" + idx] = item.amount;
          params["QTY" + idx] = item.quantity;
          })
  
          const searchParams = new URLSearchParams(params);
  
          const turl =
          "${url}/tracify.js?" + searchParams.toString();
          const req_headers = { csorigin: window.location.origin };
          tracifyReportEvent(turl, "GET", req_headers, null);
        })
        </script>
      <?php
      }`;
  } else if (shopType === "shopware5") {
    return `Instructions: Please install the latest version of the Tracify Shopware Connect Plugin.\n\nShopware5 Releases on Github: https://github.com/Tracify-ai/shopware5-plugin/releases\nShopware5 Release files on Google Drive: https://drive.google.com/drive/folders/1AhOhLPuyVq5NL_llrzWDIBP6MzvaGtRH?usp=sharing\nInstallation-Tutorial: https://www.tracify.ai/onboarding/cms#Shopware5\nCustomer Shop ID: ${csid}`;
  }
  return "";
};

/**
 * Provides the main tracking code
 * @param {string} shopType Type of shop to create tracking code for
 * @param {string} trackingChannel Tracking channel to use.
 * @param {string} backendUrl URL for corresponding backend.
 * @param {string|undefined} csid ID of the store
 * @return {string} Tracking code for store
 */
export const getPostPurchaseCode = (
  shopType: string,
  trackingChannel: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let ch;
  if (trackingChannel === "production") {
    ch = "";
  } else {
    ch = "e";
  }

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://scripting.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devscripting.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/scripting";

  if (shopType === "shopify")
    return `<script async src="${url}/pps${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "woocommerce")
    return `<script async src="${url}/ppsw${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "shopware5")
    return `<script async src="${url}/ppss${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "magento2_4")
    return `<script async src="${url}/ppsm${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "custom")
    return `<script async src="${url}/ppsc${ch}.js?csid=${csid}"></script>`;

  return "";
};

export const getWebsiteURL = (
  storeURL: string,
  useRedirectGateway: boolean,
  backendUrl: string
) => {
  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://redirect.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devredirect.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/redirect";
  return useRedirectGateway ? url : storeURL;
};

function encodeQueryParamsRaw(data: any) {
  const ret: string[] = [];
  for (const [key, value] of Object.entries(data)) {
    ret.push(`${key}=${value}`);
  }
  return ret.join("&");
}

export const getURLParams = (
  adsProvider: string,
  landingPageUrl: string,
  csid: string,
  useRedirectGateway: boolean
) => {
  let prefix = "";
  let data = {};
  if (useRedirectGateway)
    data = { ...data, csid, thost: encodeURIComponent(landingPageUrl) };
  if (adsProvider == "meta") {
    data = {
      ...data,
      trc_mcmp_id: "{{campaign.id}}",
      trc_mag_id: "{{adset.id}}",
      trc_mad_id: "{{ad.id}}",
    };
  } else if (adsProvider == "google") {
    data = {
      ...data,
      trc_gcmp_id: "{campaignid}",
      trc_gag_id: "{adgroupid}",
      trc_gad_id: "{creative}",
    };
    prefix = "{lpurl}?";
  } else if (adsProvider == "tiktok") {
    data = {
      ...data,
      trc_ttc_id: "__CAMPAIGN_ID__",
      trc_ttag_id: "__AID__",
      trc_ttad_id: "__CID__",
    };
  } else if (adsProvider == "email") {
    data = {
      ...data,
      utm_source: "campaign-email",
      utm_medium: "email",
      utm_campaign: "YOUR-CAMPAIGN-NAME",
    };
  } else if (adsProvider == "influencer") {
    data = {
      ...data,
      trc_inf_un: "{{username}}",
      trc_inf_cn: "{{channelname}}",
      trc_inf_dc: "{{discountcode}}",
    };
  } else if (adsProvider == "pinterest") {
    data = {
      ...data,
      trc_ptcmp_id: "{campaignid}",
      trc_ptag_id: "{adgroupid}",
      trc_ptad_id: "{adid}",
    };
    prefix = "{unescapedlpurl}?";
  }
  // const searchParams = new URLSearchParams(data);
  // return `${prefix}${searchParams.toString()}`;
  const searchParams = encodeQueryParamsRaw(data);
  return `${prefix}${searchParams}`;
};

export const getPrettyRefType = (reftype: string) => {
  if (reftype === "fb") return "Facebook-Account-ID";
  if (reftype === "google") return "Google-Account-ID";
  if (reftype === "klayvio") return "Klayvio-Account-ID";
  if (reftype === "tiktok") return "Tiktok-Account-ID";
  if (reftype === "pinterest") return "Pinterest-Account-ID";
  return reftype;
};
