import { useEffect, useMemo, useState } from "react";
import { batch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PlusCircle, Copy, Check, X } from "lucide-react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";

import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  Account,
  APIError,
  Store,
  Organisation,
  Approval,
  OrganisationUpdateRequest,
} from "./backendTypes";
import { listAccounts } from "./reducers/accounts";
import { listStores } from "./reducers/stores";
import {
  deleteOrganisation,
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";
import {
  createApproval,
  deleteApproval,
  listApprovals,
} from "./reducers/approvals";
import ComboboxMultiSelect from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";

function findOrganisation(
  id: string | undefined,
  organisations: Array<Organisation> | undefined
) {
  if (organisations === undefined) return undefined;
  const a = organisations.filter((org) => org.id === id);
  return a.length == 0 ? undefined : a[0];
}

function OrganisationComponent() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const { id } = useParams();
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );
  const selectedOrganisation = useSelector((state: RootState) =>
    findOrganisation(id, state.organisations.organisations)
  );
  const handleUpdate = () => {
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
  };
  useEffect(() => {
    handleUpdate();
  }, []);

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const deleteOrganisationApproval = useMemo(
    () => manageOrgasApproval && account?.rol === 255,
    [manageOrgasApproval, account?.rol]
  );

  useEffect(() => {
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (approvals.length === 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
  }, []);

  const [selectedStores, setSelectedStores] = useState<Store[] | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>(
    null
  );
  const [organisationName, setOrganisationName] = useState(
    selectedOrganisation?.name ?? ""
  );
  const [deleteOrganisationDlgOpen, setDeleteOrganisationDlgOpen] =
    useState(false);
  const [addStoreDlgOpen, setAddStoreDlgOpen] = useState(false);
  const [addAccountDlgOpen, setAddAccountDlgOpen] = useState(false);
  const [accountInputValue, setAccountInputValue] = useState("");
  const [clientNumber, setClientNumber] = useState("");

  const relevantStores = useMemo(() => {
    if (selectedOrganisation && stores) {
      const storeIds = selectedOrganisation.customer_sites?.map((el) => el.id);
      return stores?.filter((store) => storeIds.includes(store.csid)) ?? [];
    } else return [];
  }, [selectedOrganisation]);
  const generalModeEnabled = useMemo(
    () => Boolean(selectedOrganisation?.feature_flags["general_mode_enabled"]),
    [selectedOrganisation]
  );

  const relevantAccounts = useMemo(() => {
    if (selectedOrganisation && accounts) {
      const accountIds = selectedOrganisation.accounts?.map((el) => el.id);
      return (
        accounts?.filter((account) => accountIds.includes(account?.acid)) ?? []
      );
    } else return [];
  }, [selectedOrganisation]);

  useEffect(() => {
    if (selectedOrganisation) {
      const storeIds = selectedOrganisation.customer_sites?.map((el) => el.id);
      const orgaStores =
        stores?.filter((store) => storeIds.includes(store.csid)) ?? [];
      setSelectedStores(orgaStores);
      const accountIds = selectedOrganisation.accounts?.map((el) => el.id);
      const orgaAccs =
        accounts?.filter((account) => accountIds.includes(account?.acid)) ?? [];
      setSelectedAccounts(orgaAccs);
      setClientNumber(selectedOrganisation.client_number ?? "");
    }
  }, [selectedOrganisation]);

  const handleAddStore = () => {
    setAddStoreDlgOpen(true);
  };

  const handleAddStoreClose = () => {
    setAddStoreDlgOpen(false);
  };
  const handleDeleteOrganisation = () => {
    setDeleteOrganisationDlgOpen(true);
  };

  const handleDeleteOrganisationClose = () => {
    setDeleteOrganisationDlgOpen(false);
  };

  const handleDeleteOrganisationAction = () => {
    if (!selectedOrganisation) return;

    dispatch(
      deleteOrganisation({
        id: selectedOrganisation.id,
        session,
        backendUrl: chiefBackendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/organisations`);
      })
      .catch((err: APIError) => {
        toast(
          `Unable to delete organisation '${selectedOrganisation.name}': ${err.error}`
        );
      });
  };

  const handleAddAccount = () => {
    setAddAccountDlgOpen(true);
  };

  const handleAddAccountClose = () => {
    setAddAccountDlgOpen(false);
  };

  const handleAddAccountsToStores = () => {
    batch(() => {
      selectedStores?.forEach((store) => {
        selectedAccounts?.forEach((account) => {
          const approval = approvals.find(
            (appr) =>
              appr.scope === "kytron::read_events" &&
              appr.acid == account?.acid &&
              appr.rsid === store.csid
          );
          if (!approval) {
            dispatch(
              createApproval({
                acid: account?.acid,
                scope: "kytron::read_events",
                rsid: store.csid,
                rstype: 0,
                session,
                backendUrl,
              })
            );
          }
        });
      });
    });
  };
  const handleRemoveAccountsFromStores = () => {
    batch(() => {
      const removedStores = stores.filter((store) => {
        const isInSelectedStores = selectedStores?.find(
          (el) => el.csid === store.csid
        );
        const isInOrganisationStores =
          selectedOrganisation?.customer_sites.find(
            (el) => el.id === store.csid
          );
        return isInOrganisationStores && !isInSelectedStores;
      });

      // for every removed store we also delete the read approvals for the connected accounts of the organisation
      removedStores?.forEach((store) => {
        const accApprovals = selectedOrganisation?.accounts
          ?.map((account) => {
            return approvals.find(
              (appr) =>
                appr.scope === "kytron::read_events" &&
                appr.acid == account?.id &&
                appr.rsid === store.csid
            );
          })
          .filter((el) => Boolean(el)) as Approval[];
        if (accApprovals && accApprovals.length > 0) {
          dispatch(
            deleteApproval({
              asis: accApprovals.map((el) => el.asi),
              session,
              backendUrl,
            })
          );
        }
      });
      const removedAccounts = accounts.filter((account) => {
        const isInSelectedAccounts = selectedAccounts?.find(
          (el) => el.acid === account?.acid
        );
        const isInOrganisationAccounts = selectedOrganisation?.accounts.find(
          (el) => el.id === account?.acid
        );
        return isInOrganisationAccounts && !isInSelectedAccounts;
      });
      // for every removed account we also delete the read approvals for the connected stores of the organisation
      removedAccounts?.forEach((account) => {
        const storeApprovals = selectedOrganisation?.customer_sites
          ?.map((store) => {
            return approvals.find(
              (appr) =>
                appr.scope === "kytron::read_events" &&
                appr.acid == account?.acid &&
                appr.rsid === store.id
            );
          })
          .filter((el) => Boolean(el)) as Approval[];
        if (storeApprovals && storeApprovals.length > 0) {
          dispatch(
            deleteApproval({
              asis: storeApprovals.map((el) => el.asi),
              session,
              backendUrl,
            })
          );
        }
      });
    });
  };

  const handleUpdateGeneralModeFlag = (value: boolean) => {
    if (selectedOrganisation) {
      const payload: OrganisationUpdateRequest = {
        id: selectedOrganisation.id,
        name: selectedOrganisation?.name,
        customer_sites: selectedOrganisation.customer_sites,
        client_number: selectedOrganisation.client_number,
        accounts: selectedOrganisation.accounts,
        feature_flags: {
          ...selectedOrganisation.feature_flags,
          general_mode_enabled: value,
        },
        session,
        backendUrl: chiefBackendUrl,
      };

      dispatch(updateOrganisation(payload))
        .unwrap()
        .then(() => {
          handleUpdate();
          toast(
            `General mode for organisation '${selectedOrganisation?.name}' successfully updated`
          );
        })
        .catch((err: APIError) => {
          toast(
            `Unable to update organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleUpdateOrganisation = () => {
    if (selectedOrganisation) {
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: organisationName ?? selectedOrganisation?.name,
          client_number:
            clientNumber && clientNumber !== selectedOrganisation?.client_number
              ? clientNumber
              : selectedOrganisation?.client_number,
          customer_sites: selectedStores?.map((el) => ({ id: el.csid })) ?? [],
          accounts: selectedAccounts?.map((el) => ({ id: el.acid })) ?? [],
          feature_flags: { ...selectedOrganisation.feature_flags },
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          handleRemoveAccountsFromStores();
          handleAddAccountsToStores();
          setAddStoreDlgOpen(false);
          setAddAccountDlgOpen(false);
          handleUpdate();
          toast(
            `Organisation '${selectedOrganisation?.name}' successfully updated`
          );
        })
        .catch((err: APIError) => {
          toast(
            `Unable to update organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>{selectedOrganisation?.name}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <div className="flex-1">
                <Label htmlFor="org-name">Organisation Name</Label>
                <Input
                  id="org-name"
                  value={organisationName}
                  onChange={(e) => setOrganisationName(e.target.value)}
                />
              </div>
              {organisationName &&
                organisationName !== selectedOrganisation?.name && (
                  <Button onClick={handleUpdateOrganisation}>Save</Button>
                )}
            </div>

            <div className="flex items-center gap-2">
              <div className="flex-1">
                <Label htmlFor="org-id">Organisation ID</Label>
                <Input id="org-id" value={selectedOrganisation?.id} disabled />
              </div>
              <div className="mt-5">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      selectedOrganisation?.id ?? ""
                    );

                    toast.success(
                      "Organisation ID has been copied to your clipboard"
                    );
                  }}
                >
                  <Copy className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Label htmlFor="general-mode">Generic Mode enabled</Label>
              <Switch
                id="general-mode"
                checked={generalModeEnabled}
                onCheckedChange={handleUpdateGeneralModeFlag}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Stores</CardTitle>
        </CardHeader>
        <CardContent>
          <div>
            <div className="space-y-4">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Store-ID</TableHead>
                    <TableHead>Company</TableHead>
                    <TableHead>Cluster</TableHead>
                    <TableHead>URL</TableHead>
                    <TableHead>Store</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {relevantStores?.map((store) => (
                    <TableRow
                      key={store.csid}
                      className="cursor-pointer hover:bg-muted/50"
                      onClick={() => navigate(`/stores/${store.csid}`)}
                    >
                      <TableCell>{store.csid}</TableCell>
                      <TableCell>{store.name}</TableCell>
                      <TableCell>{store.pg_idx + 1}</TableCell>
                      <TableCell>{store.url}</TableCell>
                      <TableCell>{store.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {manageOrgasApproval && (
                <Button onClick={handleAddStore}>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Manage Stores
                </Button>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Accounts</CardTitle>
        </CardHeader>
        <CardContent>
          <div>
            <div className="space-y-4">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Account-ID</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Role</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {relevantAccounts?.map((account) => (
                    <TableRow
                      key={account.acid}
                      className="cursor-pointer hover:bg-muted/50"
                      onClick={() => navigate(`/accounts/${account.acid}`)}
                    >
                      <TableCell>{account.acid}</TableCell>
                      <TableCell>{account.email}</TableCell>
                      <TableCell>{account.role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {manageOrgasApproval && (
                <Button onClick={handleAddAccount}>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Manage Accounts
                </Button>
              )}
            </div>

            {deleteOrganisationApproval && (
              <div className="pt-6">
                <h3 className="text-lg font-semibold text-destructive">
                  Danger Zone
                </h3>
                <div className="mt-4">
                  <Button
                    variant="destructive"
                    onClick={handleDeleteOrganisation}
                    disabled={
                      !selectedOrganisation ||
                      selectedOrganisation?.customer_sites.length > 0
                    }
                  >
                    Delete Organisation
                  </Button>
                  {(!selectedOrganisation ||
                    selectedOrganisation?.customer_sites.length > 0) && (
                    <p className="mt-2 text-sm text-muted-foreground">
                      You must delete or remove all stores for this organisation
                      before deleting
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={deleteOrganisationDlgOpen}
        onOpenChange={setDeleteOrganisationDlgOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Delete organisation {selectedOrganisation?.name}
            </DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this organisation? This action
              cannot be undone.
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteOrganisationDlgOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteOrganisationAction}
            >
              Delete Organisation
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={addStoreDlgOpen} onOpenChange={setAddStoreDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add stores to organisation</DialogTitle>
            <DialogDescription>
              Select the stores you want to add to this organisation
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-2 text-foreground">
            <Label>Stores</Label>
            <ComboboxMultiSelect
              selectedItems={
                selectedStores?.map((store) => ({
                  id: store.csid,
                  name: store.name,
                  tooltipContent: `CSID: ${store.csid}, URL: ${store.url}`,
                })) || []
              }
              items={
                stores?.map((store) => ({
                  id: store.csid,
                  name: store.name,
                  tooltipContent: `CSID: ${store.csid}, URL: ${store.url}`,
                })) || []
              }
              onItemSelect={(item) => {
                const store = stores?.find((s) => s.csid === item.id);
                if (store) {
                  setSelectedStores([...(selectedStores || []), store]);
                }
              }}
              onItemRemove={(itemId) => {
                setSelectedStores(
                  selectedStores?.filter((s) => s.csid !== itemId) || []
                );
              }}
              placeholder="No stores selected yet"
              emptyStateText="No stores found."
              searchInputPlaceholder="Search stores..."
              badgeVariant="purple"
              onClearAll={() => setSelectedStores([])}
              showClearAll
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setAddStoreDlgOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleUpdateOrganisation}
              disabled={!selectedStores?.length}
            >
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={addAccountDlgOpen} onOpenChange={setAddAccountDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add accounts to organisation</DialogTitle>
            <DialogDescription>
              Select the accounts you want to add to this organisation
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-2 text-foreground">
            <Label>Accounts</Label>
            <ComboboxMultiSelect
              selectedItems={
                selectedAccounts?.map((account) => ({
                  id: account.acid,
                  name: account.email,
                })) || []
              }
              items={
                accounts?.map((account) => ({
                  id: account.acid,
                  name: account.email,
                })) || []
              }
              onItemSelect={(item) => {
                const account = accounts?.find((a) => a.acid === item.id);
                if (account) {
                  setSelectedAccounts([...(selectedAccounts || []), account]);
                }
              }}
              onItemRemove={(itemId) => {
                setSelectedAccounts(
                  selectedAccounts?.filter((a) => a.acid !== itemId) || []
                );
              }}
              placeholder="No accounts selected yet"
              emptyStateText="No accounts found."
              searchInputPlaceholder="Search accounts..."
              badgeVariant="sky"
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setAddAccountDlgOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateOrganisation}
              disabled={!selectedAccounts?.length}
            >
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrganisationComponent;
