import { Account } from "@/backendTypes";

export function findAccount(
  acid: string | undefined,
  accounts: Array<Account> | undefined
) {
  if (accounts === undefined) return undefined;
  const a = accounts.filter((account) => account?.acid === acid);
  return a.length == 0 ? undefined : a[0];
}

export function roleToName(role: number | string | undefined) {
  if (role === 255) return "admin";
  if (role === 128) return "master";
  return "user";
}

export function slToName(role: number | string | undefined) {
  if (role === 255) return "enterprise";
  if (role === 128) return "premium";
  return "normal";
}

export const accessScopes = [
  {
    label: "Access to Beta environment",
    value: "access_beta_environment",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Access to Dashboard",
    value: "access_rts_dashboard",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Manage Organisations",
    value: "manage_organisations",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Add users to store",
    value: "kytron::grant_site_access",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Stores",
    value: "kytron::create_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Update Stores",
    value: "kytron::update_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Accounts",
    value: "kytron::create_account",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },

  {
    label: "Delete Stores",
    value: "kytron::delete_customersite",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Delete Accounts",
    value: "kytron::delete_account",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Update Currencies used for conversion",
    value: "kytron::update_currencies",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Grant super admin access",
    value: "kytron::grant_super_admin_access",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Grant admin access",
    value: "kytron::grant_admin_access",
    permissions: ["kytron::grant_super_admin_access"],
  },
];

export const csAccessScopes = [
  {
    label: "Access to Beta environment",
    value: "access_beta_environment",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Access to Dashboard",
    value: "access_rts_dashboard",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Manage Organisations",
    value: "manage_organisations",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Add users to store",
    value: "kytron::grant_site_access",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Stores",
    value: "kytron::create_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Update Stores",
    value: "kytron::update_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Accounts",
    value: "kytron::create_account",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
];
