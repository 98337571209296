import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

interface Store {
  csid: string;
  name: string;
  description: string;
  url: string;
  pg_idx: number;
}

interface StoreSelectProps {
  stores: Store[];
  selectedStoreName: string;
  onStoreSelect: (storeName: string) => void;
  className?: string;
}

function qualifiedStoreName(store: Store): string {
  const name =
    store.url.length > 0
      ? `${store.name} - ${store.description} (${store.url})`
      : `${store.name} - ${store.description}`;
  return name + ` - cluster ${store.pg_idx + 1}`;
}

const StoreSelect: React.FC<StoreSelectProps> = ({
  stores,
  selectedStoreName,
  onStoreSelect,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [internalSelection, setInternalSelection] = useState(selectedStoreName);

  // Sync internal state with prop when it changes externally
  useEffect(() => {
    setInternalSelection(selectedStoreName);
  }, [selectedStoreName]);

  // Create a normalized string for comparison
  const normalizeString = useCallback((str: string) => {
    return str.toLowerCase().trim().replace(/\s+/g, " ");
  }, []);

  // Filter stores based on search query
  const filteredStores = stores.filter((store) => {
    if (!searchQuery) return true;

    const searchLower = normalizeString(searchQuery);
    const storeName = normalizeString(qualifiedStoreName(store));
    const searchTerms = searchLower
      .split(" ")
      .filter((term) => term.length > 0);

    return searchTerms.every((term) => storeName.includes(term));
  });

  const handleSelect = useCallback(
    (currentValue: string) => {
      const matchingStore = stores.find(
        (store) =>
          normalizeString(qualifiedStoreName(store)) ===
          normalizeString(currentValue)
      );

      if (matchingStore) {
        const exactStoreName = qualifiedStoreName(matchingStore);
        const newValue =
          normalizeString(exactStoreName) === normalizeString(internalSelection)
            ? ""
            : exactStoreName;

        setInternalSelection(newValue);
        onStoreSelect(newValue);
      }

      setOpen(false);
    },
    [stores, internalSelection, onStoreSelect]
  );

  useEffect(() => {
    if (!open) {
      setSearchQuery("");
    }
  }, [open]);

  const buttonText = internalSelection || "Select store...";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", className)}
        >
          {buttonText}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search stores..."
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
          <CommandList>
            <CommandEmpty>No store found.</CommandEmpty>
            <CommandGroup className="max-h-[200px] overflow-auto">
              {filteredStores.map((store) => {
                const storeName = qualifiedStoreName(store);
                const isSelected =
                  normalizeString(storeName) ===
                  normalizeString(internalSelection);

                return (
                  <CommandItem
                    key={store.csid}
                    value={storeName}
                    onSelect={handleSelect}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        isSelected ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {storeName}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default StoreSelect;
