import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-md border-1 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-zinc-800 text-white border-none",
        rose: "bg-rose-50 hover:shadow shadow-rose-700/20 bg-rose-500/10 text-rose-500 hover:border-rose-500/10 border border-rose-700/20",
        sky: "bg-sky-50 hover:shadow shadow-sky-700/20 bg-sky-500/10 text-sky-500 hover:border-sky-500/10 border border-sky-700/20",
        purple:
          "bg-purple-50 hover:shadow shadow-purple-700/20 bg-purple-500/10 text-purple-500 hover:border-purple-500/10 border border-purple-700/20",
        blue: "bg-blue-50 hover:shadow shadow-blue-700/20 bg-blue-500/10 text-blue-500 hover:border-blue-500/10 border border-blue-700/20",
        amber:
          "bg-amber-50 hover:shadow shadow-amber-700/20 bg-amber-500/10 text-amber-500 hover:border-amber-500/10 border border-amber-700/20",
        teal: "bg-teal-50 hover:shadow shadow-teal-700/20 bg-teal-500/10 text-teal-500 hover:border-teal-500/10 border border-teal-700/20",
        indigo:
          "bg-indigo-50 hover:shadow shadow-indigo-700/20 bg-indigo-500/10 text-indigo-500 hover:border-indigo-500/10 border border-indigo-700/20",
        pink: "bg-pink-50 hover:shadow shadow-pink-700/20 bg-pink-500/10 text-pink-500 hover:border-pink-500/10 border border-pink-700/20",
        orange:
          "bg-orange-50 hover:shadow shadow-orange-700/20 bg-orange-500/10 text-orange-500 hover:border-orange-500/10 border border-orange-700/20",
        lime: "bg-lime-50 hover:shadow shadow-lime-700/20 bg-lime-500/10 text-lime-500 hover:border-lime-500/10 border border-lime-700/20",
        emerald:
          "bg-emerald-50 hover:shadow shadow-emerald-700/20 bg-emerald-500/10 text-emerald-500 hover:border-emerald-500/10 border border-emerald-700/20",
      },
      size: {
        xxxs: "text-xxs py-0.5 px-1.5 rounded",
        xxs: "text-xs py-0.5 px-1.5 rounded",
        xs: "text-xs py-1 px-2 rounded-md",
        sm: "text-sm py-1 px-2.5 rounded-md",
        md: "text-sm py-1.5 px-3 rounded-md",
        lg: "text-base py-2 px-4 rounded-lg",
        xl: "text-lg py-2.5 px-5 rounded-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size = "xs", ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
