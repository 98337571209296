import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Building2, Store, User } from "lucide-react";
import { RootState, useAppDispatch } from "@/store";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
} from "@/components/ui/sheet";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { createApproval } from "@/reducers/approvals";
import { createOrganisation } from "@/reducers/organisations";
import { createStore } from "@/reducers/stores";
import ComboboxMultiSelect from "../ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { Account, Store as StoreType, Organisation } from "@/backendTypes";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import TUtils from "@/TUtils";
import { createAccount } from "@/reducers/accounts";
import { generatePassword } from "@/Accounts";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";

export const shopSystemOptions = [
  { label: "Shopify", value: "shopify" },
  { label: "Shopify Plus", value: "shopify_plus" },
  { label: "WooCommerce", value: "woocommerce" },
  { label: "Shopware 5", value: "shopware5" },
  { label: "Shopware 6", value: "shopware6" },
  { label: "Magento v2.4", value: "magento2_4" },
  { label: "Elopage", value: "elopage" },
  { label: "Salesforce Commerce Cloud", value: "salesforce_commerce_cloud" },
  { label: "Digistore24", value: "digistore24" },
  { label: "Custom", value: "custom" },
];

interface CommandMenuProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CommandMenu({ open, onOpenChange }: CommandMenuProps) {
  const [addOrgDialogOpen, setAddOrgDialogOpen] = useState(false);
  const [addStoreSheetOpen, setAddStoreSheetOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores ?? []);
  const accounts = useSelector(
    (state: RootState) => state.accounts.accounts ?? []
  );
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations ?? []
  );

  const [organisationName, setOrganisationName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [selectedStores, setSelectedStores] = useState<StoreType[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [storeName, setStoreName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [selectedShopSystem, setSelectedShopSystem] = useState("");
  const [selectedCluster, setSelectedCluster] = useState<number | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>(null);
  const [fbRefs, setFbRefs] = useState("");
  const [googleRefs, setGoogleRefs] = useState("");
  const [tiktokRefs, setTiktokRefs] = useState("");
  const [pinterestRefs, setPinterestRefs] = useState("");

  const [addAccountDialogOpen, setAddAccountDialogOpen] = useState(false);

  const [userMail, setUserMail] = useState("");
  const [userPassword, setUserPassword] = useState(generatePassword());
  const [userRole, setUserRole] = useState(2);
  const [userServiceLevel, setUserServiceLevel] = useState(2);

  const filteredAccounts = useMemo(() => {
    const query = searchQuery.toLowerCase();
    if (!query) return accounts;

    return accounts.filter(
      (account) =>
        account.email.toLowerCase().includes(query) ||
        account.acid.toString().includes(query)
    );
  }, [accounts, searchQuery]);

  const handleCreateAccount = async () => {
    try {
      const result = await dispatch(
        createAccount({
          email: userMail,
          password: TUtils.hash(userPassword),
          role: userRole,
          service_level: userServiceLevel,
          session,
          backendUrl,
        })
      );

      if (createAccount.fulfilled.match(result)) {
        setAddAccountDialogOpen(false);
        clearAccountForm();
        onOpenChange(false);

        // Copy credentials to clipboard
        navigator.clipboard.writeText(
          `Email: ${userMail}\nPassword: ${userPassword}`
        );

        toast.success(
          `Account created successfully. Credentials copied to clipboard.`
        );
        navigate(`/accounts/${result.payload.acid}`);
      } else if (createAccount.rejected.match(result)) {
        const error = result.payload;
        toast.error(
          `Unable to create account: ${error?.error || "Unknown error"}`
        );
      }
    } catch (err: any) {
      toast.error(`Unable to create account: ${err.error || "Unknown error"}`);
    }
  };

  const clearAccountForm = () => {
    setUserMail("");
    setUserPassword(generatePassword());
    setUserRole(2);
    setUserServiceLevel(2);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpenChange(!open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onOpenChange, open]);

  const handleAddAccountsToStores = async () => {
    const newApprovals: any[] = [];
    selectedStores.forEach((store) => {
      selectedAccounts.forEach((account) => {
        newApprovals.push(
          dispatch(
            createApproval({
              acid: account.acid,
              scope: "kytron::read_events",
              rsid: store.csid,
              rstype: 0,
              session,
              backendUrl,
            })
          )
        );
      });
    });
    return Promise.all(newApprovals);
  };

  const handleCreateOrganisation = async () => {
    try {
      const result = await dispatch(
        createOrganisation({
          name: organisationName,
          client_number: clientNumber,
          customer_sites: selectedStores.map((el) => ({ id: el.csid })),
          accounts: selectedAccounts.map((el) => ({ id: el.acid })),
          session,
          backendUrl: chiefBackendUrl,
          feature_flags: {},
        })
      );

      if (createOrganisation.fulfilled.match(result)) {
        await handleAddAccountsToStores();

        setAddOrgDialogOpen(false);
        setOrganisationName("");
        setClientNumber("");
        setSelectedStores([]);
        setSelectedAccounts([]);
        onOpenChange(false);

        toast.success(
          `Organisation '${organisationName}' created with id '${result.payload.id}'`
        );
        navigate(`/organisations/${result.payload.id}`);
      } else if (createOrganisation.rejected.match(result)) {
        const error = result.payload;
        toast.error(
          `Unable to create organisation with name '${organisationName}': ${
            error?.error || "Unknown error"
          }`
        );
      }
    } catch (err: any) {
      toast.error(
        `Unable to create organisation with name '${organisationName}': ${
          err.error || "Unknown error"
        }`
      );
    }
  };

  const clearStoreForm = () => {
    setStoreName("");
    setStoreUrl("");
    setStoreDescription("");
    setSelectedShopSystem("");
    setSelectedCluster(null);
    setSelectedOrganisation(null);
    setFbRefs("");
    setGoogleRefs("");
    setTiktokRefs("");
    setPinterestRefs("");
  };

  const handleCreateStore = async () => {
    const allRefs: Record<string, string> = {};

    if (fbRefs) {
      fbRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "fb"));
    }
    if (googleRefs) {
      googleRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "google"));
    }
    if (tiktokRefs) {
      tiktokRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "tiktok"));
    }
    if (pinterestRefs) {
      pinterestRefs
        .split(",")
        .forEach((ref) => (allRefs[ref.trim()] = "pinterest"));
    }

    try {
      const result = await dispatch(
        createStore({
          name: storeName,
          description: storeDescription,
          url: storeUrl,
          shop_system: selectedShopSystem,
          redis_idx: selectedCluster ?? 0,
          pg_idx: selectedCluster ?? 0,
          refs: allRefs,
          session,
          backendUrl,
        })
      );

      if (createStore.fulfilled.match(result)) {
        setAddStoreSheetOpen(false);
        clearStoreForm();
        onOpenChange(false);
        toast.success(`Store '${storeName}' created successfully`);
        navigate(`/stores/${result.payload.csid}`);
      } else if (createStore.rejected.match(result)) {
        const error = result.payload;
        toast.error(
          `Unable to create store: ${error?.error || "Unknown error"}`
        );
      }
    } catch (err: any) {
      toast.error(`Unable to create store: ${err.error || "Unknown error"}`);
    }
  };

  const filteredOrganisations = useMemo(() => {
    const query = searchQuery.toLowerCase();
    if (!query) return organisations;

    return organisations.filter((org) =>
      org.name.toLowerCase().includes(query)
    );
  }, [organisations, searchQuery]);

  const filteredStores = useMemo(() => {
    const query = searchQuery.toLowerCase();
    if (!query) return stores;

    return stores.filter(
      (store) =>
        store.name.toLowerCase().includes(query) ||
        (store.description &&
          store.description.toLowerCase().includes(query)) ||
        (store.url && store.url.toLowerCase().includes(query))
    );
  }, [stores, searchQuery]);

  return (
    <>
      <CommandDialog open={open} onOpenChange={onOpenChange}>
        <CommandInput
          placeholder="Type a command or search..."
          value={searchQuery}
          onValueChange={setSearchQuery}
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>

          <CommandGroup heading="Create New">
            <CommandItem onSelect={() => setAddOrgDialogOpen(true)}>
              <Building2 className="mr-2 h-4 w-4" />
              <span>New Organization</span>
            </CommandItem>
            <CommandItem onSelect={() => setAddStoreSheetOpen(true)}>
              <Store className="mr-2 h-4 w-4" />
              <span>New Store</span>
            </CommandItem>
            <CommandItem onSelect={() => setAddAccountDialogOpen(true)}>
              <User className="mr-2 h-4 w-4" />
              <span>New Account</span>
            </CommandItem>
          </CommandGroup>

          <CommandSeparator />

          {filteredOrganisations.length > 0 && (
            <CommandGroup heading="Organizations">
              {(filteredOrganisations.length > 0 && searchQuery
                ? filteredOrganisations
                : filteredOrganisations.slice(0, 10)
              ).map((org) => (
                <CommandItem
                  key={org.id}
                  onSelect={() => {
                    navigate(`/organisations/${org.id}`);
                    onOpenChange(false);
                  }}
                >
                  <Building2 className="mr-2 h-4 w-4" />
                  <div className="flex flex-col">
                    <span>{org.name}</span>
                    {org.client_number && (
                      <span className="text-sm text-muted-foreground">
                        #{org.client_number}
                      </span>
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          )}

          {filteredStores.length > 0 && (
            <CommandGroup heading="Stores">
              {(filteredStores.length > 0 && searchQuery
                ? filteredStores
                : filteredStores.slice(0, 10)
              ).map((store) => (
                <CommandItem
                  key={store.csid}
                  onSelect={() => {
                    navigate(`/stores/${store.csid}`);
                    onOpenChange(false);
                  }}
                >
                  <Store className="mr-2 h-4 w-4" />
                  <div className="flex flex-col">
                    <span>{store.name}</span>
                    <span className="text-sm text-muted-foreground">
                      {store.description}
                    </span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          )}

          {filteredAccounts.length > 0 && (
            <CommandGroup heading="Accounts">
              {(filteredAccounts.length > 0 && searchQuery
                ? filteredAccounts
                : filteredAccounts.slice(0, 10)
              ).map((account) => (
                <CommandItem
                  key={account.acid}
                  onSelect={() => {
                    navigate(`/accounts/${account.acid}`);
                    onOpenChange(false);
                  }}
                >
                  <User className="mr-2 h-4 w-4" />
                  <div className="flex flex-col">
                    <span>{account.email}</span>
                    <span className="text-sm text-muted-foreground">
                      #{account.acid}
                    </span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </CommandDialog>

      <Dialog open={addOrgDialogOpen} onOpenChange={setAddOrgDialogOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Organisation</DialogTitle>
            <DialogDescription>
              Please provide the corresponding organisation info here:
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="space-y-2 text-foreground">
              <Label htmlFor="name">Company name</Label>
              <Input
                id="name"
                required
                value={organisationName}
                onChange={(e) => setOrganisationName(e.target.value)}
              />
            </div>

            <div className="space-y-2 text-foreground">
              <Label htmlFor="clientNumber">Client Number</Label>
              <Input
                id="clientNumber"
                value={clientNumber}
                onChange={(e) => setClientNumber(e.target.value)}
              />
            </div>

            <div className="space-y-2 text-foreground">
              <Label>Stores</Label>
              <ComboboxMultiSelect
                selectedItems={selectedStores.map((store) => ({
                  id: store.csid,
                  name: store.name,
                  tooltipContent: `CSID: ${store.csid}`,
                }))}
                items={stores.map((store) => ({
                  id: store.csid,
                  name: store.name,
                  tooltipContent: `CSID: ${store.csid}`,
                }))}
                onItemSelect={(item) => {
                  const store = stores.find((s) => s.csid === item.id);
                  if (store) {
                    setSelectedStores([...selectedStores, store]);
                  }
                }}
                onItemRemove={(itemId) => {
                  setSelectedStores(
                    selectedStores.filter((s) => s.csid !== itemId)
                  );
                }}
                placeholder="No stores selected yet"
                emptyStateText="No stores found."
                searchInputPlaceholder="Search stores..."
                badgeVariant="purple"
              />
            </div>

            <div className="space-y-2 text-foreground">
              <Label>Accounts</Label>
              <ComboboxMultiSelect
                selectedItems={selectedAccounts.map((account) => ({
                  id: account.acid,
                  name: account.email,
                }))}
                items={accounts.map((account) => ({
                  id: account.acid,
                  name: account.email,
                }))}
                onItemSelect={(item) => {
                  const account = accounts.find((a) => a.acid === item.id);
                  if (account) {
                    setSelectedAccounts([...selectedAccounts, account]);
                  }
                }}
                onItemRemove={(itemId) => {
                  setSelectedAccounts(
                    selectedAccounts.filter((a) => a.acid !== itemId)
                  );
                }}
                placeholder="No accounts selected yet"
                emptyStateText="No accounts found."
                searchInputPlaceholder="Search accounts..."
                badgeVariant="sky"
              />
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setAddOrgDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateOrganisation}
              disabled={organisationName.length < 4}
            >
              Create
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Sheet open={addStoreSheetOpen} onOpenChange={setAddStoreSheetOpen}>
        <SheetContent className="w-[600px] overflow-y-auto">
          <SheetHeader>
            <SheetTitle>Add Store</SheetTitle>
            <SheetDescription>
              Please provide the store information:
            </SheetDescription>
          </SheetHeader>

          <div className="grid gap-2 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Company name</Label>
              <Input
                id="name"
                required
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="url">URL</Label>
              <Input
                id="url"
                required
                value={storeUrl}
                onChange={(e) =>
                  setStoreUrl(TUtils.validateURL(e.target.value))
                }
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="description">Store name</Label>
              <Input
                id="description"
                required
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="organisation">Organisation</Label>
              <ComboboxMultiSelect
                selectedItems={
                  selectedOrganisation
                    ? [
                        {
                          id: selectedOrganisation.id,
                          name: selectedOrganisation.name,
                        },
                      ]
                    : []
                }
                items={organisations.map((org) => ({
                  id: org.id,
                  name: org.name,
                }))}
                onItemSelect={(item) => {
                  const org = organisations.find((o) => o.id === item.id);
                  if (org) {
                    setSelectedOrganisation(org);
                  }
                }}
                onItemRemove={() => setSelectedOrganisation(null)}
                placeholder="No organisation selected"
                emptyStateText="No organisations found."
                searchInputPlaceholder="Search organisations..."
                badgeVariant="purple"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="shop-system">Shop System</Label>
              <Select
                value={selectedShopSystem}
                onValueChange={setSelectedShopSystem}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select shop system" />
                </SelectTrigger>
                <SelectContent>
                  {shopSystemOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="cluster">Cluster</Label>
              <Select
                value={selectedCluster?.toString() ?? ""}
                onValueChange={(value) => setSelectedCluster(Number(value))}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select cluster" />
                </SelectTrigger>
                <SelectContent>
                  {Array.from({ length: 5 }, (_, i) => (
                    <SelectItem key={i} value={i.toString()}>
                      Cluster {i + 1}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="fbids">Facebook Account IDs</Label>
              <Input
                id="fbids"
                value={fbRefs}
                onChange={(e) => setFbRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="googleids">Google Account IDs</Label>
              <Input
                id="googleids"
                value={googleRefs}
                onChange={(e) =>
                  setGoogleRefs(e.target.value.replace(/[^a-zA-Z0-9,]/g, ""))
                }
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="tiktokids">TikTok Account IDs</Label>
              <Input
                id="tiktokids"
                value={tiktokRefs}
                onChange={(e) => setTiktokRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="pinterestids">Pinterest Account IDs</Label>
              <Input
                id="pinterestids"
                value={pinterestRefs}
                onChange={(e) => setPinterestRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>
          </div>

          <SheetFooter className="flex-row justify-end gap-2 pt-4">
            <Button
              variant="outline"
              onClick={() => setAddStoreSheetOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateStore}
              disabled={storeName.length < 4 || !storeUrl || !storeDescription}
            >
              Create
            </Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>

      <Dialog
        open={addAccountDialogOpen}
        onOpenChange={setAddAccountDialogOpen}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Account</DialogTitle>
            <DialogDescription>
              Please provide the account information:
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email Address</Label>
              <Input
                id="email"
                type="email"
                required
                value={userMail}
                onChange={(e) => setUserMail(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <div className="flex gap-2">
                <Input
                  id="password"
                  required
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                <Button
                  variant="outline"
                  onClick={() => setUserPassword(generatePassword())}
                  type="button"
                >
                  Generate
                </Button>
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="role">Role</Label>
              <RadioGroup
                value={userRole.toString()}
                onValueChange={(value) => setUserRole(parseInt(value))}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="2" id="role-user" />
                  <Label htmlFor="role-user">User</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="128" id="role-master" />
                  <Label htmlFor="role-master">Master</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="255" id="role-admin" />
                  <Label htmlFor="role-admin">Admin</Label>
                </div>
              </RadioGroup>
            </div>

            <div className="space-y-2">
              <Label htmlFor="service-level">Service Level</Label>
              <RadioGroup
                value={userServiceLevel.toString()}
                onValueChange={(value) => setUserServiceLevel(parseInt(value))}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="2" id="sl-standard" />
                  <Label htmlFor="sl-standard">Standard</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="128" id="sl-premium" />
                  <Label htmlFor="sl-premium">Premium</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="255" id="sl-enterprise" />
                  <Label htmlFor="sl-enterprise">Enterprise</Label>
                </div>
              </RadioGroup>
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setAddAccountDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateAccount}
              disabled={userMail.length < 4}
            >
              Create
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
