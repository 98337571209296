import React from "react";
import { SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { LogIn } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  changeBackendURL,
  changeChiefBackendURL,
  changeShopConnectorBackendURL,
  changeAdConnectorBackendURL,
} from "./reducers/environment";
import { login } from "./reducers/user";
import { APIError } from "./backendTypes";
import { toast } from "sonner";
import { Logo } from "./components/Logo/Logo";

function Login() {
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies([
    "tracifyTheresaMail",
    "tracifyTheresaPwd",
  ]);
  const [email, setEmail] = useState(cookies.tracifyTheresaMail || "");
  const [password, setPassword] = useState(cookies.tracifyTheresaPwd || "");

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    dispatch(changeBackendURL(value));

    if (value.startsWith("https://hive.tracify.ai")) {
      dispatch(changeChiefBackendURL("https://chief.tracify.ai/api"));
      dispatch(
        changeShopConnectorBackendURL("https://shopconnector.tracify.ai/api")
      );
      dispatch(
        changeAdConnectorBackendURL("https://adconnector.tracify.ai/api")
      );
    } else if (value.startsWith("https://devhive.tracify.ai")) {
      dispatch(changeChiefBackendURL("https://devchief.tracify.ai/api"));
      dispatch(
        changeShopConnectorBackendURL("https://devshopconnector.tracify.ai/api")
      );
      dispatch(
        changeAdConnectorBackendURL("https://devadconnector.tracify.ai/api")
      );
    } else {
      dispatch(changeChiefBackendURL("http://localhost:8000/api"));
      dispatch(changeShopConnectorBackendURL("http://localhost:8000/api"));
      dispatch(changeAdConnectorBackendURL("https://localhost:8000/api"));
    }
  };

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    dispatch(login({ email, password, backendUrl }))
      .unwrap()
      .then(() => {
        navigate("/organisations");
      })
      .catch((err: APIError) => {
        toast(`Unable to log into account '${email}': ${err.error}`);
      });
  };

  const onEmailInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const val = event.target.value.toString();
    setEmail(val);
    setCookie("tracifyTheresaMail", val, { path: "/" });
  };

  const onPasswordInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const val = event.target.value;
    setPassword(val);
    setCookie("tracifyTheresaPwd", val, { path: "/" });
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center p-4">
      <Card className="w-full max-w-md relative">
        <div className="absolute top-[-12px] right-[10px]">
          <Logo />
        </div>
        <CardHeader>
          <CardTitle>Theresa Login</CardTitle>
          <CardDescription>Sign in to your account</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="login-email">Email</Label>
              <Input
                id="login-email"
                type="email"
                value={email}
                onChange={onEmailInputChange}
                autoComplete="username"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="login-password">Password</Label>
              <Input
                id="login-password"
                type="password"
                value={password}
                onChange={onPasswordInputChange}
                autoComplete="current-password"
              />
            </div>

            <div className="space-y-2">
              <Label>Environment</Label>
              <RadioGroup
                value={backendUrl}
                onValueChange={handleChange}
                className="flex flex-col space-y-1 sm:flex-row sm:space-x-4 sm:space-y-0"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="https://hive.tracify.ai/v1/tracify/api"
                    id="production"
                  />
                  <Label htmlFor="production">Production</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="https://devhive.tracify.ai/v1/tracify/api"
                    id="development"
                  />
                  <Label htmlFor="development">Development</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="http://127.0.1.1:8282/v1/tracify/api"
                    id="local"
                  />
                  <Label htmlFor="local">Local</Label>
                </div>
              </RadioGroup>
            </div>

            <Button type="submit" className="w-full">
              <LogIn className="mr-2 h-4 w-4" />
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
