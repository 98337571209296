import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  AlertCircle,
  CheckCircle,
  Circle,
  Loader2,
  XCircle,
} from "lucide-react";
import { Organisation } from "@/backendTypes";
import ComboboxMultiSelect, {
  Item,
} from "../ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import ProgressTracker from "../ProgressTracker/ProgressTracker";

interface AddOrgDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  organisations: Organisation[];
  onAdd: (organisationId: string, autoAssignStores: boolean) => Promise<void>;
}

export const AddOrganisationDialog: React.FC<AddOrgDialogProps> = ({
  open,
  onOpenChange,
  organisations,
  onAdd,
}) => {
  const [selectedOrganisations, setSelectedOrganisations] = useState<Item[]>(
    []
  );
  const [autoAssignStores, setAutoAssignStores] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progressItems, setProgressItems] = useState<
    Array<{
      id: string;
      name: string;
      status: "pending" | "loading" | "completed" | "error";
      error?: string;
    }>
  >([]);

  const items = organisations.map((org) => ({
    id: org.id,
    name: org.name,
    tooltipContent: `Client Number: ${org.client_number}`,
  }));

  const handleAdd = async () => {
    if (!autoAssignStores && !showWarning) {
      setShowWarning(true);
      return;
    }

    setIsProcessing(true);
    setProgressItems(
      selectedOrganisations.map((org) => ({
        id: org.id,
        name: org.name,
        status: "pending",
      }))
    );

    for (const org of selectedOrganisations) {
      setProgressItems((prev) =>
        prev.map((item) =>
          item.id === org.id ? { ...item, status: "loading" } : item
        )
      );

      try {
        await onAdd(org.id, autoAssignStores);
        setProgressItems((prev) =>
          prev.map((item) =>
            item.id === org.id ? { ...item, status: "completed" } : item
          )
        );
      } catch (error: any) {
        setProgressItems((prev) =>
          prev.map((item) =>
            item.id === org.id
              ? { ...item, status: "error", error: error.message }
              : item
          )
        );
      }
    }

    const allCompleted = progressItems.every(
      (item) => item.status === "completed" || item.status === "error"
    );

    if (allCompleted) {
      setTimeout(() => {
        handleClose();
      }, 1500);
    }
  };

  const handleClose = () => {
    setShowWarning(false);
    setSelectedOrganisations([]);
    setAutoAssignStores(false);
    setIsProcessing(false);
    setProgressItems([]);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Organisations</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {!isProcessing ? (
            <>
              <ComboboxMultiSelect
                selectedItems={selectedOrganisations}
                items={items}
                onItemSelect={(item) => {
                  setSelectedOrganisations((prev) => [...prev, item]);
                  setShowWarning(false);
                }}
                onItemRemove={(id) => {
                  setSelectedOrganisations((prev) =>
                    prev.filter((org) => org.id !== id)
                  );
                  setShowWarning(false);
                }}
                onClearAll={() => {
                  setSelectedOrganisations([]);
                  setShowWarning(false);
                }}
                placeholder="Select organisations"
                emptyStateText="No organisations found"
                searchInputPlaceholder="Search organisations..."
                showClearAll={true}
                badgeVariant="blue"
              />

              <div className="flex items-center space-x-2">
                <Switch
                  id="auto-assign"
                  checked={autoAssignStores}
                  onCheckedChange={(checked) => {
                    setAutoAssignStores(checked);
                    setShowWarning(false);
                  }}
                />
                <Label htmlFor="auto-assign">
                  Automatically assign store access
                </Label>
              </div>

              {showWarning && (
                <Alert variant="warning">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    The account will not have access to any stores in these
                    organizations. Are you sure you want to continue?
                  </AlertDescription>
                </Alert>
              )}
            </>
          ) : (
            <ProgressTracker items={progressItems} />
          )}
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            disabled={selectedOrganisations.length === 0 || isProcessing}
          >
            {isProcessing ? (
              <span className="flex items-center">Processing...</span>
            ) : (
              <span>
                Add{" "}
                {selectedOrganisations.length > 0
                  ? `(${selectedOrganisations.length})`
                  : ""}
              </span>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
