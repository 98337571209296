import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/components/ui/pagination";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { Store, EventInfo, AttributionInfo } from "./backendTypes";
import { getHeartbeat, listStores } from "./reducers/stores";
import TUtils from "./TUtils";
import { cn } from "./lib/utils";
import { toast } from "sonner";
import { Separator } from "@radix-ui/react-select";

type SortDirection = "asc" | "desc";
type EventSortField = keyof EventInfo;
type AttributionSortField = keyof AttributionInfo;

type EventHeadCell = {
  id: string;
  label: string;
  sortable: boolean;
  numeric?: boolean;
};

const eventHeadCells: readonly EventHeadCell[] = [
  {
    id: "eid",
    label: "Event-ID",
    sortable: true,
  },
  {
    id: "event",
    label: "Timestamp",
    sortable: true,
  },
  {
    id: "origin",
    label: "Origin",
    sortable: true,
  },
  {
    id: "interaction",
    label: "Interaction",
    sortable: true,
  },
  {
    id: "nitems",
    label: "Num(items)",
    sortable: true,
    numeric: true,
  },
  {
    id: "amount",
    label: "Amount",
    sortable: true,
    numeric: true,
  },
] as const;

const attributionHeadCells = [
  {
    id: "aid",
    label: "Attribution-ID",
    sortable: true,
  },
  {
    id: "event",
    label: "Timestamp",
    sortable: true,
  },
  {
    id: "adcid",
    label: "Campaign-ID",
    sortable: true,
  },
  {
    id: "adgid",
    label: "Adset-ID",
    sortable: true,
  },
  {
    id: "adid",
    label: "Ad-ID",
    sortable: true,
  },
  {
    id: "source",
    label: "Source",
    sortable: true,
  },
  {
    id: "medium",
    label: "Medium",
    sortable: true,
  },
] as const;

function qualifiedStoreName(store: Store) {
  const name =
    store.url.length > 0
      ? `${store.name} - ${store.description} (${store.url})`
      : `${store.name} - ${store.description}`;
  return name + ` - cluster ${store.pg_idx + 1}`;
}

function Status() {
  const scope = "kytron::read_events";
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [storeOpen, setStoreOpen] = useState(false);

  const [events, setEvents] = useState<EventInfo[]>([]);
  const [eventPage, setEventPage] = useState(0);
  const [eventRowsPerPage] = useState(20);
  const eventSearchText = searchParams.get("eventSearch") || "";
  const eventSortField =
    (searchParams.get("eventSortField") as EventSortField) || "event";
  const eventSortDirection =
    (searchParams.get("eventSortDirection") as SortDirection) || "desc";

  const [attributions, setAttributions] = useState<AttributionInfo[]>([]);
  const [attributionPage, setAttributionPage] = useState(0);
  const [attributionRowsPerPage] = useState(20);
  const attributionSearchText = searchParams.get("attributionSearch") || "";
  const attributionSortField =
    (searchParams.get("attributionSortField") as AttributionSortField) ||
    "event";
  const attributionSortDirection =
    (searchParams.get("attributionSortDirection") as SortDirection) || "desc";

  const [isLoading, setIsLoading] = useState(false);

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores ?? []);

  const filteredEvents = useMemo(() => {
    if (!eventSearchText) return events;
    return TUtils.filterArrayByString(events, eventSearchText);
  }, [events, eventSearchText]);

  const sortedEvents = useMemo(() => {
    if (!eventSortField) return filteredEvents;

    return [...filteredEvents].sort((a, b) => {
      let aValue = a[eventSortField];
      let bValue = b[eventSortField];

      if (eventHeadCells.find((cell) => cell.id === eventSortField)?.numeric) {
        aValue = Number(aValue);
        bValue = Number(bValue);
        return eventSortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }

      const strA = String(aValue).toLowerCase();
      const strB = String(bValue).toLowerCase();
      const comparison = strA.localeCompare(strB);
      return eventSortDirection === "asc" ? comparison : -comparison;
    });
  }, [filteredEvents, eventSortField, eventSortDirection]);

  const filteredAttributions = useMemo(() => {
    if (!attributionSearchText) return attributions;
    return TUtils.filterArrayByString(attributions, attributionSearchText);
  }, [attributions, attributionSearchText]);

  const sortedAttributions = useMemo(() => {
    if (!attributionSortField) return filteredAttributions;

    return [...filteredAttributions].sort((a, b) => {
      const aValue = String(a[attributionSortField]).toLowerCase();
      const bValue = String(b[attributionSortField]).toLowerCase();

      const comparison = aValue.localeCompare(bValue);
      return attributionSortDirection === "asc" ? comparison : -comparison;
    });
  }, [filteredAttributions, attributionSortField, attributionSortDirection]);

  useEffect(() => {
    if (stores.length === 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const handleEventSort = (field: EventSortField) => {
    let newDirection: SortDirection = "asc";
    if (field === eventSortField) {
      newDirection = eventSortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("eventSortField", field);
      params.set("eventSortDirection", newDirection);
      return params;
    });
  };

  const handleAttributionSort = (field: AttributionSortField) => {
    let newDirection: SortDirection = "asc";
    if (field === attributionSortField) {
      newDirection = attributionSortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("attributionSortField", field);
      params.set("attributionSortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (
    field: string,
    currentField: string,
    direction: SortDirection
  ) => {
    if (field !== currentField) return null;
    return direction === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleEventSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("eventSearch", searchValue);
      } else {
        params.delete("eventSearch");
      }
      return params;
    });
    setEventPage(0);
  };

  const handleAttributionSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("attributionSearch", searchValue);
      } else {
        params.delete("attributionSearch");
      }
      return params;
    });
    setAttributionPage(0);
  };

  const handleUpdate = async () => {
    if (!selectedStore) return;

    setIsLoading(true);
    try {
      const response = await dispatch(
        getHeartbeat({
          csids: [selectedStore.csid],
          num_entries: 50,
          session,
          backendUrl,
        })
      ).unwrap();

      setEvents(response.events ?? []);
      setAttributions(response.attributions ?? []);
    } catch (error: any) {
      toast.error(
        `Unable to retrieve heartbeat: ${error.error || "Unknown error"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderPagination = (
    page: number,
    setPage: (page: number) => void,
    totalItems: number,
    rowsPerPage: number
  ) => (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <button
            onClick={() => page > 0 && setPage(page - 1)}
            className={cn(
              "px-2 py-1 rounded hover:bg-muted flex gap-1",
              page === 0 && "opacity-50 cursor-not-allowed"
            )}
            disabled={page === 0}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </button>
        </PaginationItem>

        {[...Array(Math.ceil(totalItems / rowsPerPage))].map((_, idx) => {
          if (
            idx === 0 ||
            idx === Math.ceil(totalItems / rowsPerPage) - 1 ||
            (idx >= page - 2 && idx <= page + 2)
          ) {
            return (
              <PaginationItem key={idx}>
                <button
                  onClick={() => setPage(idx)}
                  className={cn(
                    "px-3 py-1 rounded",
                    page === idx
                      ? "bg-primary text-primary-foreground"
                      : "hover:bg-muted"
                  )}
                >
                  {idx + 1}
                </button>
              </PaginationItem>
            );
          } else if (
            idx === 1 ||
            idx === Math.ceil(totalItems / rowsPerPage) - 2
          ) {
            return (
              <PaginationItem key={idx}>
                <PaginationEllipsis />
              </PaginationItem>
            );
          }
          return null;
        })}

        <PaginationItem>
          <button
            onClick={() =>
              page < Math.ceil(totalItems / rowsPerPage) - 1 &&
              setPage(page + 1)
            }
            className={cn(
              "px-2 py-1 rounded hover:bg-muted flex gap-1",
              page >= Math.ceil(totalItems / rowsPerPage) - 1 &&
                "opacity-50 cursor-not-allowed"
            )}
            disabled={page >= Math.ceil(totalItems / rowsPerPage) - 1}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </button>
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <CardTitle>Status</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-4">
            <Popover open={storeOpen} onOpenChange={setStoreOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={storeOpen}
                  className="truncate max-w-[500px] w-[500px] justify-between"
                >
                  {selectedStore
                    ? qualifiedStoreName(selectedStore)
                    : "Select store..."}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[500px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search stores..."
                    className="truncate max-w-[300px]"
                  />
                  <CommandList>
                    <CommandEmpty>No store found.</CommandEmpty>
                    <CommandGroup className="max-h-[300px] overflow-auto">
                      {stores.map((store) => (
                        <CommandItem
                          key={store.csid}
                          onSelect={() => {
                            setSelectedStore(store);
                            setStoreOpen(false);
                          }}
                        >
                          {qualifiedStoreName(store)}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            <Button
              onClick={handleUpdate}
              disabled={!selectedStore || isLoading}
              className="gap-2"
            >
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <RefreshCw className="h-4 w-4" />
              )}
              Update
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Events</CardTitle>
          <div className="flex items-center space-x-2 pt-3">
            <Search className="h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search events..."
              value={eventSearchText}
              onChange={handleEventSearchChange}
              className="w-[300px]"
            />
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                {eventHeadCells.map((cell) => (
                  <TableHead
                    key={cell.id}
                    className={cn(
                      cell.sortable && "cursor-pointer select-none"
                    )}
                    onClick={() =>
                      cell.sortable &&
                      handleEventSort(cell.id as EventSortField)
                    }
                  >
                    <span className="flex items-center">
                      {cell.label}
                      {cell.sortable &&
                        getSortIcon(
                          cell.id,
                          eventSortField,
                          eventSortDirection
                        )}
                    </span>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedEvents
                .slice(
                  eventPage * eventRowsPerPage,
                  eventPage * eventRowsPerPage + eventRowsPerPage
                )
                .map((row) => (
                  <TableRow key={row.eid} className="hover:bg-muted/50">
                    <TableCell>{row.eid}</TableCell>
                    <TableCell>{row.event}</TableCell>
                    <TableCell>{row.origin}</TableCell>
                    <TableCell>{row.interaction}</TableCell>
                    <TableCell className="text-right">{row.nitems}</TableCell>
                    <TableCell className="text-right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              {sortedEvents.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={eventHeadCells.length}
                    className="text-center h-24"
                  >
                    No events found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={eventHeadCells.length}>
                  <div className="flex items-center flex-col justify-between py-2">
                    {renderPagination(
                      eventPage,
                      setEventPage,
                      sortedEvents.length,
                      eventRowsPerPage
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Attributions</CardTitle>
          <div className="flex items-center space-x-2 pt-3">
            <Search className="h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search attributions..."
              value={attributionSearchText}
              onChange={handleAttributionSearchChange}
              className="w-[300px]"
            />
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                {attributionHeadCells.map((cell) => (
                  <TableHead
                    key={cell.id}
                    className={cn(
                      cell.sortable && "cursor-pointer select-none"
                    )}
                    onClick={() =>
                      cell.sortable &&
                      handleAttributionSort(cell.id as AttributionSortField)
                    }
                  >
                    <span className="flex items-center">
                      {cell.label}
                      {cell.sortable &&
                        getSortIcon(
                          cell.id,
                          attributionSortField,
                          attributionSortDirection
                        )}
                    </span>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedAttributions
                .slice(
                  attributionPage * attributionRowsPerPage,
                  attributionPage * attributionRowsPerPage +
                    attributionRowsPerPage
                )
                .map((row) => (
                  <TableRow key={row.aid} className="hover:bg-muted/50">
                    <TableCell>{row.aid}</TableCell>
                    <TableCell>{row.event}</TableCell>
                    <TableCell>{row.adcid}</TableCell>
                    <TableCell>{row.adgid}</TableCell>
                    <TableCell>{row.adid}</TableCell>
                    <TableCell>{row.source}</TableCell>
                    <TableCell>{row.medium}</TableCell>
                  </TableRow>
                ))}
              {sortedAttributions.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={attributionHeadCells.length}
                    className="text-center h-24"
                  >
                    No attributions found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={attributionHeadCells.length}>
                  <div className="flex items-center flex-col justify-between py-2">
                    {renderPagination(
                      attributionPage,
                      setAttributionPage,
                      sortedAttributions.length,
                      attributionRowsPerPage
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}

export default Status;
