import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import { Layout } from "./AppBar";

import Login from "./Login";
import Accounts from "./Accounts";
import Stores from "./ClientStores";
import Store from "./ClientStore";
import Status from "./Status";
import Account from "./Account";
import OrderStatus from "./OrderStatus";
import Organisations from "./Organisations";
import Organisation from "./Organisation";
import SyncingPage from "./SyncingPage";
import { checkUserAccount } from "./reducers/user";
import { RootState, useAppDispatch as useDispatch } from "./store";
import { Logo } from "./components/Logo/Logo";

const LoadingSpinner = () => (
  <div className="flex w-full h-full min-h-screen items-center justify-center flex-col gap-2">
    <Logo />
    <p className="ml-2">Loading Theresa App</p>
  </div>
);

type ProtectedRouteProps = {
  element: React.ReactElement;
  isAuthenticated: boolean;
  isInitialized: boolean;
};

const ProtectedRoute = ({
  element,
  isAuthenticated,
  isInitialized,
}: ProtectedRouteProps) => {
  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Layout>{element}</Layout>;
};

function Routing() {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const account = useSelector((state: RootState) => state.user.account);
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          await dispatch(checkUserAccount({ backendUrl }));
        }
      } catch (error) {
        localStorage.removeItem("token");
      } finally {
        setIsInitialized(true);
      }
    };

    initializeApp();
  }, [dispatch, backendUrl]);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/login"
          element={account ? <Navigate to="/" replace /> : <Login />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Organisations />}
            />
          }
        />
        <Route
          path="/organisations"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Organisations />}
            />
          }
        />
        <Route
          path="/organisations/:id"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Organisation />}
            />
          }
        />
        <Route
          path="/accounts"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Accounts />}
            />
          }
        />
        <Route
          path="/accounts/:acid"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Account />}
            />
          }
        />
        <Route
          path="/stores"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Stores />}
            />
          }
        />
        <Route
          path="/stores/:csid"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Store />}
            />
          }
        />
        <Route
          path="/status"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<Status />}
            />
          }
        />
        <Route
          path="/syncing"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<SyncingPage />}
            />
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              isInitialized={isInitialized}
              element={<OrderStatus />}
            />
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default Routing;
