import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Store } from "@/backendTypes";
import ComboboxMultiSelect, {
  Item,
} from "../ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import ProgressTracker from "../ProgressTracker/ProgressTracker";

interface StoreApprovalDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  stores: Store[];
  relevantStores: Store[];
  onApprove: (stores: Item[]) => Promise<void>;
}

export const StoreApprovalDialog: React.FC<StoreApprovalDialogProps> = ({
  open,
  onOpenChange,
  stores,
  relevantStores,
  onApprove,
}) => {
  const [selectedStores, setSelectedStores] = useState<Item[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progressItems, setProgressItems] = useState<
    Array<{
      id: string;
      name: string;
      status: "pending" | "loading" | "completed" | "error";
      error?: string;
    }>
  >([]);

  const storeItems: Item[] = stores
    .filter((store) => !relevantStores.map((s) => s.csid).includes(store.csid))
    .map((store) => ({
      id: store.csid,
      name: store.name,
      tooltipContent: `${store.url} - ${store.description}`,
    }));

  const handleStoreSelect = (item: Item) => {
    setSelectedStores([...selectedStores, item]);
  };

  const handleStoreRemove = (storeId: string) => {
    setSelectedStores(selectedStores.filter((store) => store.id !== storeId));
  };

  const handleClearStores = () => {
    setSelectedStores([]);
  };

  const handleClose = () => {
    setSelectedStores([]);
    setIsProcessing(false);
    setProgressItems([]);
    onOpenChange(false);
  };

  const handleApprove = async () => {
    if (selectedStores.length === 0) return;

    setIsProcessing(true);
    setProgressItems(
      selectedStores.map((store) => ({
        id: store.id,
        name: store.name,
        status: "pending",
      }))
    );

    try {
      await onApprove(selectedStores);

      // Update all items to completed
      setProgressItems((prev) =>
        prev.map((item) => ({ ...item, status: "completed" }))
      );

      // Close dialog after a brief delay to show completion state
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (error: any) {
      // Update items to error state
      setProgressItems((prev) =>
        prev.map((item) => ({
          ...item,
          status: "error",
          error: error.message,
        }))
      );
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Approve store access</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 overflow-hidden">
          {!isProcessing ? (
            <ComboboxMultiSelect
              selectedItems={selectedStores}
              items={storeItems}
              onItemSelect={handleStoreSelect}
              onItemRemove={handleStoreRemove}
              onClearAll={handleClearStores}
              placeholder="Select stores to approve access"
              emptyStateText="No stores found"
              searchInputPlaceholder="Search stores..."
              showClearAll={true}
              badgeVariant="blue"
            />
          ) : (
            <ProgressTracker items={progressItems} />
          )}
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApprove}
            disabled={!selectedStores.length || isProcessing}
          >
            {isProcessing ? (
              <span className="flex items-center">Processing...</span>
            ) : (
              <span>
                Approve{" "}
                {selectedStores.length > 0 ? `(${selectedStores.length})` : ""}
              </span>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
