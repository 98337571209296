import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Plus,
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
  Copy,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/components/ui/pagination";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Badge } from "@/components/ui/badge";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { Account } from "./backendTypes";
import { listAccounts, createAccount } from "./reducers/accounts";
import { createApproval, listApprovals } from "./reducers/approvals";
import TUtils from "./TUtils";
import { cn } from "./lib/utils";
import { toast } from "sonner";
import { Entropy } from "entropy-string";
import { DataFilter } from "./components/ui/data-filter";

type SortDirection = "asc" | "desc";
type SortField = keyof Pick<
  Account,
  "acid" | "email" | "role" | "service_level" | "active"
>;

const headCells = [
  {
    id: "acid",
    label: "Account-ID",
    sortable: true,
  },
  {
    id: "email",
    label: "Email",
    sortable: true,
  },
  {
    id: "role",
    label: "Role",
    sortable: true,
  },
  {
    id: "service_level",
    label: "Service-Level",
    sortable: true,
  },
  {
    id: "active",
    label: "Status",
    sortable: true,
  },
] as const;

const adminApprovals = [
  "kytron::create_account",
  "kytron::create_customersite",
  "kytron::create_event",
  "kytron::read_events",
];

const rtsApprovalScope = "access_rts_dashboard";

function roleToName(role: number) {
  if (role === 255) return "admin";
  if (role === 128) return "master";
  return "user";
}

function slToName(level: number) {
  if (level === 255) return "enterprise";
  if (level === 128) return "premium";
  return "normal";
}

export const generatePassword = () => {
  const entropy = new Entropy();
  return entropy.string();
};

function Accounts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [addDlgOpen, setAddDlgOpen] = useState(false);

  const [userMail, setUserMail] = useState("");
  const [userPassword, setUserPassword] = useState(generatePassword());
  const [userRole, setUserRole] = useState(2);
  const [userServiceLevel, setUserServiceLevel] = useState(2);

  const searchText = searchParams.get("search") || "";
  const sortField = (searchParams.get("sortField") as SortField) || "email";
  const sortDirection =
    (searchParams.get("sortDirection") as SortDirection) || "asc";

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const accounts = useSelector(
    (state: RootState) => state.accounts.accounts ?? []
  );

  const [rows, setRows] = useState<Account[]>([]);

  const selectedRoles = (searchParams.get("roles")?.split(",") || []).filter(
    Boolean
  );
  const selectedServiceLevels = (
    searchParams.get("serviceLevels")?.split(",") || []
  ).filter(Boolean);
  const selectedStatuses = (
    searchParams.get("statuses")?.split(",") || []
  ).filter(Boolean);

  const handleFilterChange = useCallback(
    (filterName: string, values: string[]) => {
      setPage(0);
      setSearchParams(
        (prev) => {
          const params = new URLSearchParams(prev);
          if (values.length > 0) {
            params.set(filterName, values.join(","));
          } else {
            params.delete(filterName);
          }
          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const filteredAndSortedRows = useMemo(() => {
    let result = [...accounts];

    // Apply text search filter
    if (searchText) {
      const lowerSearchText = searchText.toLowerCase();
      result = result.filter(
        (account) =>
          account.email.toLowerCase().includes(lowerSearchText) ||
          account.acid.toString().includes(lowerSearchText) ||
          roleToName(account.role).toLowerCase().includes(lowerSearchText) ||
          slToName(account.service_level)
            .toLowerCase()
            .includes(lowerSearchText)
      );
    }

    // Apply role filter
    if (selectedRoles.length > 0) {
      result = result.filter((account) =>
        selectedRoles.includes(roleToName(account.role))
      );
    }

    // Apply service level filter
    if (selectedServiceLevels.length > 0) {
      result = result.filter((account) =>
        selectedServiceLevels.includes(slToName(account.service_level))
      );
    }

    // Apply status filter
    if (selectedStatuses.length > 0) {
      result = result.filter((account) =>
        selectedStatuses.includes(account.active ? "active" : "inactive")
      );
    }

    // Apply sorting
    if (sortField) {
      result.sort((a, b) => {
        let aValue: string | number | boolean = a[sortField];
        let bValue: string | number | boolean = b[sortField];

        if (sortField === "role") {
          aValue = roleToName(a.role);
          bValue = roleToName(b.role);
        } else if (sortField === "service_level") {
          aValue = slToName(a.service_level);
          bValue = slToName(b.service_level);
        }

        if (typeof aValue === "boolean") {
          return sortDirection === "asc"
            ? aValue === bValue
              ? 0
              : aValue
              ? 1
              : -1
            : aValue === bValue
            ? 0
            : aValue
            ? -1
            : 1;
        }

        const strA = String(aValue).toLowerCase();
        const strB = String(bValue).toLowerCase();

        return sortDirection === "asc"
          ? strA.localeCompare(strB)
          : strB.localeCompare(strA);
      });
    }

    return result;
  }, [
    accounts,
    searchText,
    selectedRoles,
    selectedServiceLevels,
    selectedStatuses,
    sortField,
    sortDirection,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (accounts.length === 0) {
          await dispatch(listAccounts({ session, backendUrl }));
        }
        await dispatch(listApprovals({ session, backendUrl }));
      } catch (error) {
        toast.error("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchText) {
      const lowerSearchText = searchText.toLowerCase();
      const filteredRows = accounts.filter(
        (account) =>
          account.email.toLowerCase().includes(lowerSearchText) ||
          account.acid.toString().includes(lowerSearchText) ||
          roleToName(account.role).toLowerCase().includes(lowerSearchText) ||
          slToName(account.service_level)
            .toLowerCase()
            .includes(lowerSearchText) ||
          (account.active ? "active" : "inactive").includes(lowerSearchText)
      );
      setRows(filteredRows);
      setPage(0);
    } else {
      setRows(accounts);
    }
  }, [accounts, searchText]);

  const handleSort = (field: SortField) => {
    setPage(0);
    let newDirection: SortDirection = "asc";
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortField", field);
      params.set("sortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("search", searchValue);
      } else {
        params.delete("search");
      }
      return params;
    });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    Promise.all([
      dispatch(listAccounts({ session, backendUrl })),
      dispatch(listApprovals({ session, backendUrl })),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  const handleCreateAccount = async () => {
    try {
      const response = await dispatch(
        createAccount({
          email: userMail,
          password: TUtils.hash(userPassword),
          role: userRole,
          service_level: userServiceLevel,
          session,
          backendUrl,
        })
      ).unwrap();

      if (response.created) {
        setAddDlgOpen(false);
        toast.success(
          `Account created successfully. Password copied to clipboard.`
        );

        navigator.clipboard.writeText(
          `Email: '${userMail}'\nPassword: ${userPassword}`
        );

        if (userRole === 255) {
          // Admin approvals
          await Promise.all([
            ...adminApprovals.map((scope) =>
              dispatch(
                createApproval({
                  acid: response.acid,
                  scope,
                  rsid: undefined,
                  rstype: 0,
                  session,
                  backendUrl,
                })
              )
            ),
            dispatch(
              createApproval({
                acid: response.acid,
                scope: rtsApprovalScope,
                rsid: undefined,
                rstype: 0,
                session,
                backendUrl,
              })
            ),
          ]);
        } else if (userRole === 128) {
          // Master approvals
          await Promise.all([
            dispatch(
              createApproval({
                acid: response.acid,
                scope: "kytron::read_events",
                rsid: undefined,
                rstype: 0,
                session,
                backendUrl,
              })
            ),
            dispatch(
              createApproval({
                acid: response.acid,
                scope: rtsApprovalScope,
                rsid: undefined,
                rstype: 0,
                session,
                backendUrl,
              })
            ),
          ]);
        } else {
          // Basic user approval
          await dispatch(
            createApproval({
              acid: response.acid,
              scope: rtsApprovalScope,
              rsid: undefined,
              rstype: 0,
              session,
              backendUrl,
            })
          );
        }

        clearForm();
        handleUpdate();
      } else {
        toast.error(`Account with email '${userMail}' already exists!`);
      }
    } catch (err: any) {
      toast.error(
        `Unable to create account with email '${userMail}': ${
          err.error || "Unknown error"
        }`
      );
    }
  };

  const clearForm = () => {
    setUserMail("");
    setUserPassword(generatePassword());
    setUserRole(2);
    setUserServiceLevel(2);
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Existing user accounts</CardTitle>
          <div className="flex justify-between items-center pt-3">
            <div className="flex items-center space-x-2">
              <Search className="h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search accounts..."
                value={searchText}
                onChange={handleSearchChange}
                className="w-[300px]"
              />
            </div>
            <div className="flex gap-3">
              <DataFilter
                title="Role"
                value={selectedRoles}
                onValueChange={(values) => handleFilterChange("roles", values)}
                options={[
                  { label: "Admin", value: "admin" },
                  { label: "Master", value: "master" },
                  { label: "User", value: "user" },
                ]}
              />
              <DataFilter
                title="Service Level"
                value={selectedServiceLevels}
                onValueChange={(values) =>
                  handleFilterChange("serviceLevels", values)
                }
                options={[
                  { label: "Enterprise", value: "enterprise" },
                  { label: "Premium", value: "premium" },
                  { label: "Normal", value: "normal" },
                ]}
              />
              <DataFilter
                title="Status"
                value={selectedStatuses}
                onValueChange={(values) =>
                  handleFilterChange("statuses", values)
                }
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </div>
            <div className="space-x-4">
              <Button
                variant="secondary"
                onClick={handleUpdate}
                className="gap-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="h-4 w-4" />
                )}
                Refresh
              </Button>
              <Button
                onClick={() => {
                  setUserPassword(generatePassword());
                  setAddDlgOpen(true);
                }}
                className="gap-2"
                disabled={isLoading}
              >
                <Plus className="h-4 w-4" />
                Add User
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    {headCells.map((cell) => (
                      <TableHead
                        key={cell.id}
                        className={cn(
                          cell.sortable && "cursor-pointer select-none"
                        )}
                        onClick={() =>
                          cell.sortable && handleSort(cell.id as SortField)
                        }
                      >
                        <span className="flex items-center">
                          {cell.label}
                          {cell.sortable && getSortIcon(cell.id)}
                        </span>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredAndSortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.acid}
                        className="cursor-pointer hover:bg-muted/50"
                        onClick={() => navigate(`/accounts/${row.acid}`)}
                      >
                        <TableCell className="group flex gap-2">
                          {row.acid}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.acid);
                              toast("Account ID copied to clipboard");
                            }}
                            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                          >
                            <Copy className="h-4 w-4" />
                          </button>
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <Badge variant="blue">{roleToName(row.role)}</Badge>
                        </TableCell>
                        <TableCell>
                          <Badge variant="purple">
                            {slToName(row.service_level)}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <Badge variant={row.active ? "emerald" : "rose"}>
                            {row.active ? "active" : "inactive"}
                          </Badge>
                        </TableCell>
                      </TableRow>
                    ))}
                  {filteredAndSortedRows.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length}
                        className="text-center h-24"
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className="flex items-center flex-col justify-between py-2">
                        <Pagination>
                          <PaginationContent>
                            <PaginationItem>
                              <button
                                onClick={() => page > 0 && setPage(page - 1)}
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page === 0 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                                Previous
                              </button>
                            </PaginationItem>

                            {[
                              ...Array(
                                Math.ceil(
                                  filteredAndSortedRows.length / rowsPerPage
                                )
                              ),
                            ].map((_, idx) => {
                              if (
                                idx === 0 ||
                                idx ===
                                  Math.ceil(
                                    filteredAndSortedRows.length / rowsPerPage
                                  ) -
                                    1 ||
                                (idx >= page - 2 && idx <= page + 2)
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <button
                                      onClick={() => setPage(idx)}
                                      className={cn(
                                        "px-3 py-1 rounded",
                                        page === idx
                                          ? "bg-primary text-primary-foreground"
                                          : "hover:bg-muted"
                                      )}
                                    >
                                      {idx + 1}
                                    </button>
                                  </PaginationItem>
                                );
                              } else if (
                                idx === 1 ||
                                idx ===
                                  Math.ceil(
                                    filteredAndSortedRows.length / rowsPerPage
                                  ) -
                                    2
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <PaginationEllipsis />
                                  </PaginationItem>
                                );
                              }
                              return null;
                            })}

                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page <
                                    Math.ceil(
                                      filteredAndSortedRows.length / rowsPerPage
                                    ) -
                                      1 && setPage(page + 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page >=
                                    Math.ceil(
                                      filteredAndSortedRows.length / rowsPerPage
                                    ) -
                                      1 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={
                                  page >=
                                  Math.ceil(
                                    filteredAndSortedRows.length / rowsPerPage
                                  ) -
                                    1
                                }
                              >
                                Next
                                <ChevronRight className="h-4 w-4" />
                              </button>
                            </PaginationItem>
                          </PaginationContent>
                        </Pagination>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          )}
        </CardContent>
      </Card>

      <Dialog open={addDlgOpen} onOpenChange={setAddDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add User</DialogTitle>
            <DialogDescription>
              Please provide the user information:
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email Address</Label>
              <Input
                id="email"
                type="email"
                required
                value={userMail}
                onChange={(e) => setUserMail(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <div className="flex gap-2">
                <Input
                  id="password"
                  required
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                <Button
                  variant="outline"
                  onClick={() => setUserPassword(generatePassword())}
                  type="button"
                >
                  Generate
                </Button>
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="role">Role</Label>
              <RadioGroup
                value={userRole.toString()}
                onValueChange={(value) => setUserRole(parseInt(value))}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="2" id="role-user" />
                  <Label htmlFor="role-user">User</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="128" id="role-master" />
                  <Label htmlFor="role-master">Master</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="255" id="role-admin" />
                  <Label htmlFor="role-admin">Admin</Label>
                </div>
              </RadioGroup>
            </div>

            <div className="space-y-2">
              <Label htmlFor="service-level">Service Level</Label>
              <RadioGroup
                value={userServiceLevel.toString()}
                onValueChange={(value) => setUserServiceLevel(parseInt(value))}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="2" id="sl-standard" />
                  <Label htmlFor="sl-standard">Standard</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="128" id="sl-premium" />
                  <Label htmlFor="sl-premium">Premium</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="255" id="sl-enterprise" />
                  <Label htmlFor="sl-enterprise">Enterprise</Label>
                </div>
              </RadioGroup>
            </div>
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setAddDlgOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleCreateAccount}
              disabled={userMail.length < 4}
            >
              Create
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Accounts;
