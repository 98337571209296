import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const alertVariants = cva(
  "relative w-full rounded-lg border border-neutral-200 px-4 py-3 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-neutral-950 [&>svg~*]:pl-7 dark:border-neutral-800 dark:[&>svg]:text-neutral-50",
  {
    variants: {
      variant: {
        default: "bg-zinc-900 text-gray-500 dark:text-gray-300 border-none",
        rose: "bg-rose-50 hover:shadow shadow-rose-700/20 bg-rose-500/10 text-rose-500 hover:border-rose-500/10 border border-rose-700/20",
        sky: "bg-sky-50 hover:shadow shadow-sky-700/20 bg-sky-500/10 text-sky-500 hover:border-sky-500/10 border border-sky-700/20",
        purple:
          "bg-purple-50 hover:shadow shadow-purple-700/20 bg-purple-500/10 text-purple-500 hover:border-purple-500/10 border border-purple-700/20",
        blue: "bg-blue-50 hover:shadow shadow-blue-700/20 bg-blue-500/10 text-blue-500 hover:border-blue-500/10 border border-blue-700/20",
        amber:
          "bg-amber-50 hover:shadow shadow-amber-700/20 bg-amber-500/10 text-amber-500 hover:border-amber-500/10 border border-amber-700/20",
        teal: "bg-teal-50 hover:shadow shadow-teal-700/20 bg-teal-500/10 text-teal-500 hover:border-teal-500/10 border border-teal-700/20",
        indigo:
          "bg-indigo-50 hover:shadow shadow-indigo-700/20 bg-indigo-500/10 text-indigo-500 hover:border-indigo-500/10 border border-indigo-700/20",
        pink: "bg-pink-50 hover:shadow shadow-pink-700/20 bg-pink-500/10 text-pink-500 hover:border-pink-500/10 border border-pink-700/20",
        orange:
          "bg-orange-50 hover:shadow shadow-orange-700/20 bg-orange-500/10 text-orange-500 hover:border-orange-500/10 border border-orange-700/20",
        lime: "bg-lime-50 hover:shadow shadow-lime-700/20 bg-lime-500/10 text-lime-500 hover:border-lime-500/10 border border-lime-700/20",
        emerald:
          "bg-emerald-50 hover:shadow shadow-emerald-700/20 bg-emerald-500/10 text-emerald-500 hover:border-emerald-500/10 border border-emerald-700/20",
        warning:
          "bg-orange-50 hover:shadow shadow-orange-700/20 bg-orange-500/10 text-orange-500 hover:border-orange-500/10 border border-orange-700/20",
        destructive:
          "bg-red-50 hover:shadow shadow-red-700/20 bg-red-500/10 text-red-500 hover:border-red-500/10 border border-red-700/20",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
