import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Plus,
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
  X,
  Copy,
} from "lucide-react";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
} from "@/components/ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { Store, Organisation } from "./backendTypes";
import { listStores, createStore } from "./reducers/stores";
import { listApprovals } from "./reducers/approvals";
import { listSiterefs } from "./reducers/siterefs";
import { listOrganisations } from "./reducers/organisations";
import TUtils from "./TUtils";
import { cn } from "./lib/utils";
import { toast } from "sonner";
import ComboboxMultiSelect from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { Badge } from "./components/ui/badge";
import { DataFilter } from "./components/ui/data-filter";

type SortDirection = "asc" | "desc";
type SortField = keyof Pick<
  Store,
  "csid" | "name" | "url" | "description" | "pg_idx"
>;

const headCells = [
  {
    id: "csid",
    label: "Store-ID",
    sortable: true,
  },
  {
    id: "name",
    label: "Company",
    sortable: true,
  },
  {
    id: "active",
    label: "Status",
    sortable: true,
  },
  {
    id: "pg_idx",
    label: "Cluster",
    sortable: true,
  },
  {
    id: "url",
    label: "URL",
    sortable: true,
  },
  {
    id: "description",
    label: "Store",
    sortable: true,
  },
] as const;

export const shopSystemOptions = [
  { label: "Shopify", value: "shopify" },
  { label: "Shopify Plus", value: "shopify_plus" },
  { label: "WooCommerce", value: "woocommerce" },
  { label: "Shopware 5", value: "shopware5" },
  { label: "Shopware 6", value: "shopware6" },
  { label: "Magento v2.4", value: "magento2_4" },
  { label: "Elopage", value: "elopage" },
  { label: "Salesforce Commerce Cloud", value: "salesforce_commerce_cloud" },
  { label: "Digistore24", value: "digistore24" },
  { label: "Custom", value: "custom" },
];

function Stores() {
  const scope = "kytron::read_events";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [addDlgOpen, setAddDlgOpen] = useState(false);

  const [storeName, setStoreName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [selectedShopSystem, setSelectedShopSystem] = useState("");
  const [selectedCluster, setSelectedCluster] = useState<number | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>(null);

  const [fbRefs, setFbRefs] = useState("");
  const [googleRefs, setGoogleRefs] = useState("");
  const [tiktokRefs, setTiktokRefs] = useState("");
  const [pinterestRefs, setPinterestRefs] = useState("");

  const searchText = searchParams.get("search") || "";
  const sortField = (searchParams.get("sortField") as SortField) || "name";
  const sortDirection =
    (searchParams.get("sortDirection") as SortDirection) || "asc";

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores ?? []);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations ?? []
  );

  const selectedStatuses = (
    searchParams.get("statuses")?.split(",") || []
  ).filter(Boolean);
  const selectedClusters = (
    searchParams.get("clusters")?.split(",") || []
  ).filter(Boolean);
  const selectedShopSystems = (
    searchParams.get("shopSystems")?.split(",") || []
  ).filter(Boolean);

  const [rows, setRows] = useState<Store[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const promises = [];
        if (stores.length === 0) {
          promises.push(dispatch(listStores({ scope, session, backendUrl })));
        }
        if (organisations.length === 0) {
          promises.push(
            dispatch(
              listOrganisations({ session, backendUrl: chiefBackendUrl })
            )
          );
        }

        await Promise.all([
          ...promises,
          dispatch(listApprovals({ session, backendUrl })),
          dispatch(listSiterefs({ session, backendUrl })),
        ]);
      } catch (error) {
        toast.error("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchText) {
      setRows(TUtils.filterArrayByString(stores, searchText));
      setPage(0);
    } else {
      setRows(stores);
    }
  }, [stores, searchText]);

  const handleSort = (field: SortField) => {
    let newDirection: SortDirection = "asc";
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortField", field);
      params.set("sortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("search", searchValue);
      } else {
        params.delete("search");
      }
      return params;
    });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    Promise.all([
      dispatch(listStores({ scope, session, backendUrl })),
      dispatch(listApprovals({ session, backendUrl })),
      dispatch(listSiterefs({ session, backendUrl })),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  const handleCreateStore = async () => {
    const allRefs: Record<string, string> = {};

    if (fbRefs) {
      fbRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "fb"));
    }
    if (googleRefs) {
      googleRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "google"));
    }
    if (tiktokRefs) {
      tiktokRefs.split(",").forEach((ref) => (allRefs[ref.trim()] = "tiktok"));
    }
    if (pinterestRefs) {
      pinterestRefs
        .split(",")
        .forEach((ref) => (allRefs[ref.trim()] = "pinterest"));
    }

    try {
      const response = await dispatch(
        createStore({
          name: storeName,
          url: storeUrl,
          shop_system: selectedShopSystem,
          description: storeDescription,
          refs: allRefs,
          pg_idx: selectedCluster ?? 0,
          redis_idx: selectedCluster ?? 0,
          session,
          backendUrl,
        })
      ).unwrap();

      setAddDlgOpen(false);
      clearForm();
      handleUpdate();
      toast.success(
        `Store '${storeName}' created with csid '${response.csid}'`
      );
    } catch (err: any) {
      toast.error(
        `Unable to create store with name '${storeName}': ${
          err.error || "Unknown error"
        }`
      );
    }
  };

  const clearForm = () => {
    setStoreName("");
    setStoreUrl("");
    setStoreDescription("");
    setSelectedShopSystem("");
    setSelectedCluster(null);
    setSelectedOrganisation(null);
    setFbRefs("");
    setGoogleRefs("");
    setTiktokRefs("");
    setPinterestRefs("");
  };

  const handleFilterChange = useCallback(
    (filterName: string, values: string[]) => {
      setPage(0);
      setSearchParams(
        (prev) => {
          const params = new URLSearchParams(prev);
          if (values.length > 0) {
            params.set(filterName, values.join(","));
          } else {
            params.delete(filterName);
          }
          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const filteredAndSortedRows = useMemo(() => {
    // Start with all stores
    let result = [...stores];

    // Apply text search filter
    if (searchText) {
      result = TUtils.filterArrayByString(result, searchText);
    }

    // Status filter
    if (selectedStatuses.length > 0) {
      result = result.filter((store) =>
        selectedStatuses.includes(store.active ? "active" : "inactive")
      );
    }

    // Cluster filter
    if (selectedClusters.length > 0) {
      result = result.filter((store) =>
        selectedClusters.includes(store.pg_idx.toString())
      );
    }

    // Shop system filter
    if (selectedShopSystems.length > 0) {
      result = result.filter((store) =>
        selectedShopSystems.includes(store.shop_system)
      );
    }

    // Apply sorting if field is specified
    if (sortField) {
      result.sort((a, b) => {
        const compareValue = (valA: any, valB: any) => {
          if (typeof valA === "boolean") {
            return valA === valB ? 0 : valA ? 1 : -1;
          }

          const strA = String(valA).toLowerCase();
          const strB = String(valB).toLowerCase();
          return strA.localeCompare(strB);
        };

        const aValue = a[sortField];
        const bValue = b[sortField];

        const result = compareValue(aValue, bValue);
        return sortDirection === "asc" ? result : -result;
      });
    }

    return result;
  }, [
    stores,
    searchText,
    selectedStatuses,
    selectedClusters,
    selectedShopSystems,
    sortField,
    sortDirection,
  ]);

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Existing stores</CardTitle>
          <div className="w-full">
            <div className="flex justify-between pt-3">
              <div className="flex gap-2">
                <div className="flex items-center space-x-2">
                  <Search className="h-4 w-4 text-muted-foreground" />
                  <Input
                    placeholder="Search stores..."
                    value={searchText}
                    onChange={handleSearchChange}
                    className="h-9"
                  />
                </div>
                <div className="flex gap-3">
                  <DataFilter
                    title="Status"
                    value={selectedStatuses}
                    onValueChange={(values) =>
                      handleFilterChange("statuses", values)
                    }
                    options={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                  />
                  <DataFilter
                    title="Cluster"
                    value={selectedClusters}
                    onValueChange={(values) =>
                      handleFilterChange("clusters", values)
                    }
                    options={Array.from({ length: 5 }, (_, i) => ({
                      label: `Cluster ${i + 1}`,
                      value: i.toString(),
                    }))}
                  />
                  <DataFilter
                    title="Shop System"
                    value={selectedShopSystems}
                    onValueChange={(values) =>
                      handleFilterChange("shopSystems", values)
                    }
                    options={shopSystemOptions}
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <Button
                  variant="secondary"
                  onClick={handleUpdate}
                  className="gap-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <RefreshCw className="h-4 w-4" />
                  )}
                  Refresh
                </Button>
                <Button
                  onClick={() => setAddDlgOpen(true)}
                  className="gap-2"
                  disabled={isLoading}
                >
                  <Plus className="h-4 w-4" />
                  Add Store
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardContent>
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    {headCells.map((cell) => (
                      <TableHead
                        key={cell.id}
                        className={cn(
                          cell.sortable && "cursor-pointer select-none"
                        )}
                        onClick={() =>
                          cell.sortable && handleSort(cell.id as SortField)
                        }
                      >
                        <span className="flex items-center">
                          {cell.label}
                          {cell.sortable && getSortIcon(cell.id)}
                        </span>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredAndSortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.csid}
                        className="cursor-pointer hover:bg-muted/50"
                        onClick={() => navigate(`/stores/${row.csid}`)}
                      >
                        <TableCell className="group">
                          <div className="flex gap-2">
                            {row.csid}{" "}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(row.csid);
                                toast("CSID copied to clipboard");
                              }}
                              className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                            >
                              <Copy className="h-4 w-4" />
                            </button>
                          </div>
                        </TableCell>

                        <TableCell className="flex gap-2 group">
                          {row.name}

                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.name);
                              toast("Name copied to clipboard");
                            }}
                            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                          >
                            <Copy className="h-4 w-4" />
                          </button>
                        </TableCell>
                        <TableCell>
                          <Badge variant={row.active ? "emerald" : "rose"}>
                            {row.active ? "Active" : "Inactive"}
                          </Badge>
                        </TableCell>
                        <TableCell>{row.pg_idx + 1}</TableCell>
                        <TableCell className="flex gap-2 group">
                          {row.url}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.url);
                              toast("URL copied to clipboard");
                            }}
                            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                          >
                            <Copy className="h-4 w-4" />
                          </button>
                        </TableCell>
                        <TableCell className="group flex gap-2">
                          {row.description}{" "}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.description);
                              toast("Description copied to clipboard");
                            }}
                            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                          >
                            <Copy className="h-4 w-4" />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {filteredAndSortedRows.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length}
                        className="text-center h-24"
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className="flex items-center flex-col justify-between py-2">
                        <Pagination>
                          <PaginationContent>
                            <PaginationItem>
                              <button
                                onClick={() => page > 0 && setPage(page - 1)}
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page === 0 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                                Previous
                              </button>
                            </PaginationItem>

                            {[
                              ...Array(
                                Math.ceil(
                                  filteredAndSortedRows.length / rowsPerPage
                                )
                              ),
                            ].map((_, idx) => {
                              if (
                                idx === 0 ||
                                idx ===
                                  Math.ceil(
                                    filteredAndSortedRows.length / rowsPerPage
                                  ) -
                                    1 ||
                                (idx >= page - 2 && idx <= page + 2)
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <button
                                      onClick={() => setPage(idx)}
                                      className={cn(
                                        "px-3 py-1 rounded",
                                        page === idx
                                          ? "bg-primary text-primary-foreground"
                                          : "hover:bg-muted"
                                      )}
                                    >
                                      {idx + 1}
                                    </button>
                                  </PaginationItem>
                                );
                              } else if (
                                idx === 1 ||
                                idx ===
                                  Math.ceil(
                                    filteredAndSortedRows.length / rowsPerPage
                                  ) -
                                    2
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <PaginationEllipsis />
                                  </PaginationItem>
                                );
                              }
                              return null;
                            })}

                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page <
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                  setPage(page + 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page >=
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                    "opacity-50 cursor-not-allowed"
                                )}
                                disabled={
                                  page >=
                                  Math.ceil(rows.length / rowsPerPage) - 1
                                }
                              >
                                Next
                                <ChevronRight className="h-4 w-4" />
                              </button>
                            </PaginationItem>
                          </PaginationContent>
                        </Pagination>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          )}
        </CardContent>
      </Card>

      <Sheet open={addDlgOpen} onOpenChange={setAddDlgOpen}>
        <SheetContent className="w-[600px] overflow-y-auto">
          <SheetHeader>
            <SheetTitle>Add Store</SheetTitle>
            <SheetDescription>
              Please provide the store information:
            </SheetDescription>
          </SheetHeader>

          <div className="grid gap-2 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Company name</Label>
              <Input
                id="name"
                required
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="url">URL</Label>
              <Input
                id="url"
                required
                value={storeUrl}
                onChange={(e) =>
                  setStoreUrl(TUtils.validateURL(e.target.value))
                }
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="description">Store name</Label>
              <Input
                id="description"
                required
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="organisation">Organisation</Label>
              <ComboboxMultiSelect
                selectedItems={
                  selectedOrganisation
                    ? [
                        {
                          id: selectedOrganisation.id,
                          name: selectedOrganisation.name,
                        },
                      ]
                    : []
                }
                items={organisations.map((org) => ({
                  id: org.id,
                  name: org.name,
                }))}
                onItemSelect={(item) => {
                  const org = organisations.find((o) => o.id === item.id);
                  if (org) {
                    setSelectedOrganisation(org);
                  }
                }}
                onItemRemove={() => setSelectedOrganisation(null)}
                placeholder="No organisation selected"
                emptyStateText="No organisations found."
                searchInputPlaceholder="Search organisations..."
                badgeVariant="purple"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="shop-system">Shop System</Label>
              <Select
                value={selectedShopSystem}
                onValueChange={setSelectedShopSystem}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select shop system" />
                </SelectTrigger>
                <SelectContent>
                  {shopSystemOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="cluster">Cluster</Label>
              <Select
                value={selectedCluster?.toString() ?? ""}
                onValueChange={(value) => setSelectedCluster(Number(value))}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select cluster" />
                </SelectTrigger>
                <SelectContent>
                  {Array.from({ length: 5 }, (_, i) => (
                    <SelectItem key={i} value={i.toString()}>
                      Cluster {i + 1}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="fbids">Facebook Account IDs</Label>
              <Input
                id="fbids"
                value={fbRefs}
                onChange={(e) => setFbRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="googleids">Google Account IDs</Label>
              <Input
                id="googleids"
                value={googleRefs}
                onChange={(e) =>
                  setGoogleRefs(e.target.value.replace(/[^a-zA-Z0-9,]/g, ""))
                }
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="tiktokids">TikTok Account IDs</Label>
              <Input
                id="tiktokids"
                value={tiktokRefs}
                onChange={(e) => setTiktokRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="pinterestids">Pinterest Account IDs</Label>
              <Input
                id="pinterestids"
                value={pinterestRefs}
                onChange={(e) => setPinterestRefs(e.target.value)}
                placeholder="Comma-separated IDs"
              />
            </div>
          </div>

          <SheetFooter className="flex-row justify-end gap-2 pt-4">
            <Button variant="outline" onClick={() => setAddDlgOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleCreateStore}
              disabled={storeName.length < 4 || !storeUrl || !storeDescription}
            >
              Create
            </Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
}

export default Stores;
