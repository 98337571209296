import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
  AlertCircle,
  CheckCircle2,
  Copy,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/components/ui/pagination";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { listStores } from "./reducers/stores";
import { listAdAccounts } from "./reducers/ad-accounts";
import { listSiterefs } from "./reducers/siterefs";
import { listOrganisations } from "./reducers/organisations";
import { AdAccount } from "./backendTypes";
import { DateTime } from "luxon";
import { cn } from "./lib/utils";
import { Link } from "react-router-dom";
import { DataFilter } from "./components/ui/data-filter";
import { toast } from "sonner";

type SortDirection = "asc" | "desc";
type SortField = keyof (AdAccount & {
  storeName: string;
  csid: string;
  organisation: string;
  organisationId: string;
});

const headCells = [
  {
    id: "organisation",
    label: "Organisation",
    sortable: true,
  },
  {
    id: "storeName",
    label: "Store",
    sortable: true,
  },
  {
    id: "csid",
    label: "CSID",
    sortable: true,
  },
  {
    id: "source",
    label: "Reference Type",
    sortable: true,
  },
  {
    id: "account_id",
    label: "Reference-ID",
    sortable: true,
  },
  {
    id: "last_scrape_date",
    label: "Last Scrape",
    sortable: true,
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
  },
] as const;

function SyncingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scope = "kytron::read_events";

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [errorMessage, setErrorMessage] = useState("");

  const searchText = searchParams.get("search") || "";
  const sortField =
    (searchParams.get("sortField") as SortField) || "last_scrape_date";
  const sortDirection =
    (searchParams.get("sortDirection") as SortDirection) || "asc";
  const selectedSources = (
    searchParams.get("sources")?.split(",") || []
  ).filter(Boolean);
  const selectedStatuses = (
    searchParams.get("statuses")?.split(",") || []
  ).filter(Boolean);
  const selectedOrganizations = (
    searchParams.get("organizations")?.split(",") || []
  ).filter(Boolean);

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const adconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.adconnectorBackendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const stores = useSelector((state: RootState) => state.stores.stores);
  const adAccountsState = useSelector(
    (state: RootState) => state.adAccounts.adAccounts
  );
  const siterefs = useSelector((state: RootState) => state.siterefs.siterefs);

  const [rows, setRows] = useState<
    (AdAccount & {
      storeName: string;
      csid: string;
      organisation: string;
      organisationId: string;
    })[]
  >([]);

  const parsedSearchParams = useMemo(() => {
    return {
      searchText: searchParams.get("search") || "",
      sortField:
        (searchParams.get("sortField") as SortField) || "last_scrape_date",
      sortDirection:
        (searchParams.get("sortDirection") as SortDirection) || "asc",
      selectedSources: (searchParams.get("sources")?.split(",") || []).filter(
        Boolean
      ),
      selectedStatuses: (searchParams.get("statuses")?.split(",") || []).filter(
        Boolean
      ),
      selectedOrganizations: (
        searchParams.get("organizations")?.split(",") || []
      ).filter(Boolean),
    };
  }, [searchParams]);

  const adAccounts = useMemo(() => {
    if (
      adAccountsState.length === 0 ||
      siterefs.length === 0 ||
      stores.length === 0
    ) {
      return [];
    }

    const accounts = [];

    for (const adAccount of adAccountsState) {
      const adAccountCopy = structuredClone(adAccount) as AdAccount & {
        storeName: string;
        csid: string;
        organisation: string;
        organisationId: string;
      };

      const siteRef = siterefs.find(
        (siteref) => siteref.refid === adAccount.account_id
      );
      if (siteRef) {
        const store = stores.find((el) => el.csid === siteRef.csid);
        if (store) {
          const organisation = organisations.find(
            (el) =>
              el.customer_sites.findIndex((cs) => cs.id === store.csid) !== -1
          );

          adAccountCopy.storeName = store.description;
          adAccountCopy.organisation = organisation?.name ?? "";
          adAccountCopy.organisationId = organisation?.id ?? "";
          adAccountCopy.csid = store.csid;
          if (store.active) {
            accounts.push(adAccountCopy);
          }
        }
      }
    }

    return accounts;
  }, [adAccountsState, siterefs, stores, organisations]);

  const filteredAdAccounts = useMemo(() => {
    let result = [...adAccounts];

    // Apply existing filters
    if (searchText) {
      const lowerSearchText = searchText.toLowerCase();
      result = result.filter(
        (account) =>
          account.organisation?.toLowerCase().includes(lowerSearchText) ||
          account.storeName?.toLowerCase().includes(lowerSearchText) ||
          account.csid?.toLowerCase().includes(lowerSearchText) ||
          account.source?.toLowerCase().includes(lowerSearchText) ||
          account.account_id?.toLowerCase().includes(lowerSearchText)
      );
    }

    if (selectedStatuses.length > 0) {
      result = result.filter((account) =>
        selectedStatuses.includes(account.status)
      );
    }

    if (selectedSources.length > 0) {
      result = result.filter((account) =>
        selectedSources.includes(account.source)
      );
    }

    if (selectedOrganizations.length > 0) {
      result = result.filter((account) =>
        selectedOrganizations.includes(account.organisation)
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      const compareValues = (aVal: any, bVal: any): number => {
        if (aVal === null || aVal === undefined) return -1;
        if (bVal === null || bVal === undefined) return 1;

        if (sortField === "last_scrape_date") {
          const aTime = new Date(aVal).getTime();
          const bTime = new Date(bVal).getTime();
          return sortDirection === "asc" ? aTime - bTime : bTime - aTime;
        }

        if (typeof aVal === "string" && typeof bVal === "string") {
          const aStr = aVal.toLowerCase();
          const bStr = bVal.toLowerCase();
          return sortDirection === "asc"
            ? aStr.localeCompare(bStr)
            : bStr.localeCompare(aStr);
        }

        if (typeof aVal === "number" && typeof bVal === "number") {
          return sortDirection === "asc" ? aVal - bVal : bVal - aVal;
        }

        // Fallback comparison
        return sortDirection === "asc"
          ? String(aVal).localeCompare(String(bVal))
          : String(bVal).localeCompare(String(aVal));
      };

      return compareValues(a[sortField], b[sortField]);
    });

    return result;
  }, [
    adAccounts,
    selectedStatuses,
    selectedOrganizations,
    selectedSources,
    searchText,
    sortField,
    sortDirection,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (stores.length === 0) {
          await dispatch(listStores({ scope, session, backendUrl }));
        }
        if (organisations.length === 0) {
          await dispatch(
            listOrganisations({ session, backendUrl: chiefBackendUrl })
          );
        }
        if (siterefs.length === 0) {
          await dispatch(listSiterefs({ session, backendUrl }));
        }
        if (adAccountsState.length === 0) {
          await dispatch(
            listAdAccounts({ session, backendUrl: adconnectorBackendUrl })
          );
        }
      } catch (error) {
        setErrorMessage("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterOptions = useMemo(() => {
    const sources = new Set<string>();
    const organizations = new Set<string>();
    const statuses = new Set<string>();

    adAccounts.forEach((account) => {
      if (account.source) sources.add(account.source);
      if (account.organisation) organizations.add(account.organisation);
      if (account.status) statuses.add(account.status);
    });

    return {
      sources: Array.from(sources).map((source) => ({
        label: source,
        value: source,
      })),
      organizations: Array.from(organizations).map((org) => ({
        label: org,
        value: org,
      })),
      statuses: Array.from(statuses).map((status) => ({
        label: status === "error" ? "Error" : "Success",
        value: status,
      })),
    };
  }, [adAccounts]);

  const handleFilterChange = useCallback(
    (filterName: string, values: string[]) => {
      setPage(0);

      setSearchParams(
        (prev) => {
          const params = new URLSearchParams(prev);
          if (values.length > 0) {
            params.set(filterName, values.join(","));
          } else {
            params.delete(filterName);
          }
          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const handleSort = (field: SortField) => {
    let newDirection: SortDirection = "asc";
    setPage(0);
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortField", field);
      params.set("sortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("search", searchValue);
      } else {
        params.delete("search");
      }
      return params;
    });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    Promise.all([
      dispatch(listStores({ scope, session, backendUrl })),
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl })),
      dispatch(listSiterefs({ session, backendUrl })),
      dispatch(listAdAccounts({ session, backendUrl: adconnectorBackendUrl })),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Sync History</CardTitle>
          <div className="flex items-center space-x-2 pt-3">
            <div className="flex items-center gap-5">
              <div className="w-[300px]">
                <div className="flex items-center space-x-2">
                  <Search className="h-4 w-4 text-muted-foreground" />
                  <Input
                    placeholder="Search sync history..."
                    value={searchText}
                    onChange={handleSearchChange}
                    className="h-9"
                  />
                </div>
              </div>
              <DataFilter
                title="Source"
                value={selectedSources}
                onValueChange={(values) =>
                  handleFilterChange("sources", values)
                }
                options={filterOptions.sources}
              />
              <DataFilter
                title="Organization"
                value={selectedOrganizations}
                onValueChange={(values) =>
                  handleFilterChange("organizations", values)
                }
                options={filterOptions.organizations}
              />
              <DataFilter
                title="Status"
                value={selectedStatuses}
                onValueChange={(values) =>
                  handleFilterChange("statuses", values)
                }
                options={filterOptions.statuses}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {errorMessage && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    {headCells.map((cell) => (
                      <TableHead
                        key={cell.id}
                        className={cn(
                          cell.sortable && "cursor-pointer select-none"
                        )}
                        onClick={() =>
                          cell.sortable && handleSort(cell.id as SortField)
                        }
                      >
                        <span className="flex items-center">
                          {cell.label}
                          {cell.sortable && getSortIcon(cell.id)}
                        </span>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredAdAccounts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.account_id}>
                        <TableCell>
                          <Link
                            to={
                              row?.organisationId
                                ? `/organisations/${row.organisationId}`
                                : "#"
                            }
                            className="text-foreground hover:underline"
                          >
                            {row.organisation}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/stores/${row.csid}`}
                            className="text-foreground hover:underline"
                          >
                            {row.storeName}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/stores/${row.csid}`}
                            className="text-foreground hover:underline group flex gap-2"
                          >
                            {row.csid}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(row.csid);
                                toast("CSID copied to clipboard");
                              }}
                              className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                            >
                              <Copy className="h-4 w-4" />
                            </button>
                          </Link>
                        </TableCell>
                        <TableCell>{row.source}</TableCell>
                        <TableCell className="flex gap-2 group">
                          {row.account_id}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.account_id);
                              toast("Refference copied to clipboard");
                            }}
                            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-muted rounded"
                          >
                            <Copy className="h-4 w-4" />
                          </button>
                        </TableCell>
                        <TableCell>
                          {DateTime.fromISO(row.last_scrape_date)?.isValid
                            ? DateTime.fromISO(row.last_scrape_date).toFormat(
                                "yyyy-MM-dd HH:mm:ss"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          <Badge
                            variant={
                              row.status === "error" ? "rose" : "emerald"
                            }
                            className="flex items-center gap-1"
                          >
                            {row.status === "error" ? (
                              <>
                                <AlertCircle className="h-3 w-3" /> Error
                              </>
                            ) : (
                              <>
                                <CheckCircle2 className="h-3 w-3" /> Success
                              </>
                            )}
                          </Badge>
                        </TableCell>
                      </TableRow>
                    ))}
                  {filteredAdAccounts.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length}
                        className="text-center h-24"
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className="flex items-center flex-col justify-between py-2">
                        <Pagination>
                          <PaginationContent>
                            <PaginationItem>
                              <button
                                onClick={() => page > 0 && setPage(page - 1)}
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page === 0 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                                Previous
                              </button>
                            </PaginationItem>

                            {[
                              ...Array(
                                Math.ceil(
                                  filteredAdAccounts.length / rowsPerPage
                                )
                              ),
                            ].map((_, idx) => {
                              if (
                                idx === 0 ||
                                idx ===
                                  Math.ceil(
                                    filteredAdAccounts.length / rowsPerPage
                                  ) -
                                    1 ||
                                (idx >= page - 2 && idx <= page + 2)
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <button
                                      onClick={() => setPage(idx)}
                                      className={cn(
                                        "px-3 py-1 rounded",
                                        page === idx
                                          ? "bg-primary text-primary-foreground"
                                          : "hover:bg-muted"
                                      )}
                                    >
                                      {idx + 1}
                                    </button>
                                  </PaginationItem>
                                );
                              } else if (
                                idx === 1 ||
                                idx ===
                                  Math.ceil(
                                    filteredAdAccounts.length / rowsPerPage
                                  ) -
                                    2
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <PaginationEllipsis />
                                  </PaginationItem>
                                );
                              }
                              return null;
                            })}

                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page <
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                  setPage(page + 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page >=
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                    "opacity-50 cursor-not-allowed"
                                )}
                                disabled={
                                  page >=
                                  Math.ceil(rows.length / rowsPerPage) - 1
                                }
                              >
                                Next
                                <ChevronRight className="h-4 w-4" />
                              </button>
                            </PaginationItem>
                          </PaginationContent>
                        </Pagination>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>

              <div className="mt-4 space-x-4">
                <Button
                  variant="secondary"
                  onClick={handleUpdate}
                  className="gap-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <RefreshCw className="h-4 w-4" />
                  )}
                  Refresh
                </Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default SyncingPage;
